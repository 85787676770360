import Spin from '@/components/basic/spin';
import theme from '@/style';
import {debounce, goTo} from '@/utils';
import React, {useCallback, useRef, useState} from 'react';
import {View, ScrollView} from 'react-native';
import DigitTab from './digit-tab';
import BetsDigitLevelItem from '../components/bets-digit-level';
import RuleModal from '@/components/business/games/rule';
import DigitRule from '../digit-rule';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import BetsDigit from '../bets-digit';
import Text from '@/components/basic/text';
import {
  betsListItem,
  getQuickPickData,
  PickGamesContent,
  DigitPickInfoQuickDataResponse,
  crateQuickGame,
  quickDrawByIssueNum,
} from '../digit-service';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {useConfirm} from '@basicComponents/modal';
import QuickDigit from './quick-digit';
import GoldWin from '@/components/business/games/gold-win';
import {checkIsShown, saveIssueNum} from '@/components/utils/gameWin';
import QuickTabView from './quick-tab-view';
import Video from '@/components/basic/video';
import ShopCart from '@/components/business/games/cart/cart';
import BetsItem from '../components/bets-item';
import parabolaService from '@/components/basic/parabola/parabola.service';
import QuickHeader from './quickHeader';
import QuickDigitPicker from '@/common-pages/pick-digit/components/quick-digit-picker';
import ChatBottom from '@/common-pages/pick-digit/quick/chat-bottom';
import BetModal from '@/common-pages/pick-digit/quick/betModal';
import {ReactComponent as CloseSvg} from '@/assets/icons/close.svg';
import {ReactComponent as HowPlaySvg} from '@/assets/icons/how-to-play.svg';
import useRenderRemainTime from '@/common-pages/pick-digit/components/quick-digit-header-status/useRenderRemainTime';
import {sendImAdd3DLocalMessage} from '@/common-pages/im/sendImAddLocalMessage';
import ChatBottomSend from '@/common-pages/pick-digit/quick/chat-bottom-send';

const QuickGame = () => {
  const {
    id = '',
    pickName,
    pickGameType = 'normal',
    cycle = 0,
  } = (useRoute().params as {
    id?: string;
    pickName?: string;
    cycle?: number;
    pickGameType?: string;
  }) || {};
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [quickData, setQuickData] =
    React.useState<DigitPickInfoQuickDataResponse>();
  const [quickQueryList, setQuickQueryList] =
    React.useState<PickGamesContent[]>();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [betsList, setBetsList] = React.useState<betsListItem[]>([]);
  const timeCurrent = React.useRef(0);
  const [disabled, setDisabled] = React.useState(false);
  const {i18n} = useTranslation();
  const [isDel, setIsDel] = React.useState(false);
  const [winPaused, setWinPaused] = React.useState(true);
  const lockShow = React.useRef(false);
  const [showWin, setShowWin] = React.useState(false);
  const [winAmount, setWinAmount] = React.useState(0);
  const [refreshCode, setRefreshCode] = React.useState(0);
  const refreshCodeRef = React.useRef(0);
  const [orderNum, setOrderNum] = React.useState(0);
  const totalOrder = React.useRef(0);
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
    isDel ? i18n.t('label.del') : i18n.t('label.confirm'),
  );
  const [index, setIndex] = React.useState(0);
  const [timeVersion, setTimeVersion] = React.useState(0);
  const {getState} = useNavigation();
  const {index: routeIndex, routes} = getState();
  const currentRouteName = routes[routeIndex].name;
  const endElement = React.useRef<View>(null);
  const handleUpdateAmount = useCallback(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next({gameType: '3Digit'});
    }
  }, []);
  useFocusEffect(handleUpdateAmount);

  React.useEffect(() => {
    if (id) {
      getQuickConfig();
    }
    return () => {
      queryWinId.current && clearTimeout(queryWinId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryWinId = React.useRef<NodeJS.Timeout>();
  const queryWin = (selectInfo: PickGamesContent) => {
    quickDrawByIssueNum(
      selectInfo.pickThreeID,
      selectInfo.lastResult!.pickNo,
    ).then(async (result: number) => {
      if (result && result > 0) {
        // 中奖
        const isShown = await checkIsShown(
          'digit',
          selectInfo.lastResult!.pickNo,
        );
        if (!isShown) {
          setWinAmount(result);
          setShowWin(true);
          setWinPaused(false);
          globalStore.updateAmount.next({gameType: '3Digit'});
          saveIssueNum('digit', selectInfo.lastResult!.pickNo);
        }
      }
    });
  };

  // qucik3D
  const getQuickConfig = async (active?: number) => {
    try {
      setLoading(true);
      const res = await getQuickPickData();
      if (res) {
        const games = res.pickGames;
        const currentI = games.findIndex(
          (item: {cycle: number}) => item.cycle === cycle,
        );
        const activeI =
          active !== undefined ? active : currentI === -1 ? 0 : currentI;
        lockShow.current = false;
        const selectInfo = games[activeI];
        console.log('quick-getconfig', res);
        // setCutdownTime(selectInfo.drawTimeLong);
        setTimeVersion(v => v + 1);
        setQuickData(res);
        setQuickQueryList(res.pickGames);
        if (active === undefined) {
          setActiveIndex(activeI);
        }
        if (globalStore.token) {
          if (timeCurrent.current > 5) {
            queryWin(selectInfo);
          } else {
            queryWinId.current = setTimeout(() => {
              queryWin(selectInfo);
            }, timeCurrent.current * 1000);
          }
        }
      }
    } finally {
      setDisabled(false);
      setLoading(false);
      refreshCodeRef.current += 1;
      setRefreshCode(refreshCodeRef.current);
    }
  };

  const drawerRef = React.useRef<DrawerRef>(null);

  const handleAdd = ({
    level,
    type,
    value,
    count,
    basePrice,
    pickInfoId,
  }: {
    level: number;
    type: string;
    value: string;
    count: number;
    basePrice: number;
    pickInfoId: number;
  }) => {
    // 这里优先阻止一下添加，type跟value长度不一致不应该添加购买
    if (type.length !== value.length) {
      return;
    }
    const hasItem = betsList.find(
      item => item.value === value && item.type === type,
    );
    if (hasItem) {
      const newList = betsList.map(item => {
        if (item.value === value && item.type === type) {
          return Object.assign({}, item, {count: item.count + count});
        }
        return item;
      });
      setBetsList(newList);
    } else {
      betsList.push({
        level,
        type,
        value,
        count,
        basePrice,
        pickInfoId,
      });
      setBetsList([...betsList]);
    }
  };

  const priceAndNum = React.useMemo(() => {
    let totalPrice = 0;
    let totalNum = 0;
    if (betsList.length > 0) {
      betsList.map(item => {
        totalPrice += item.count * item.basePrice;
        totalNum += item.count;
      });
    }
    return {
      total: totalPrice,
      num: totalNum,
    };
  }, [betsList]);

  const handleBox = ({
    value,
    count,
    basePrice,
    pickInfoId,
  }: {
    value: string;
    count: number;
    basePrice: number;
    pickInfoId: number;
  }) => {
    if (value.length !== 3) {
      return;
    }
    const valueArr = value.split('');
    if (valueArr[0] === valueArr[1] && valueArr[0] === valueArr[2]) {
      handleAdd({
        value: valueArr.join(''),
        type: 'ABC',
        count,
        level: 3,
        basePrice,
        pickInfoId,
      });
      return null;
    }
    const values = ['012', '021', '102', '120', '201', '210'];
    values.forEach(item => {
      const itemIndex = item.split('');
      // const types = itemIndex.map(pie => ['A', 'B', 'C'][Number(pie)]).join('');
      const targetV = itemIndex.map(pie => valueArr[Number(pie)]).join('');
      handleAdd({
        value: targetV,
        type: 'ABC',
        count,
        level: 3,
        basePrice,
        pickInfoId,
      });
    });
  };

  const onPaySubmit = async () => {
    if (disabled || loading) {
      return;
    }
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    try {
      setLoading(true);
      let res;
      if (activeQuickInfo) {
        res = await crateQuickGame({
          pickThreeId: activeQuickInfo.pickThreeID,
          pickNo: activeQuickInfo?.currentPickNo,
          totalAmount: priceAndNum.total,
          tickets: betsList.map(item => {
            return {
              pickInfoId: item.pickInfoId,
              pickIndex: item.type,
              pickNumber: item.value,
              pickCount: item.count,
            };
          }),
        });
      }
      if (res) {
        globalStore.globalSucessTotal(
          i18n.t('paidSuccess.label.subTitle'),
          i18n.t('game-page.tip.paySuccess'),
        );
        if (index === 0) {
          totalOrder.current += 1;
          setOrderNum(totalOrder.current);
        }
        globalStore.updateAmount.next({gameType: '3Digit'});
        // 这里进行im的交互，用户im端手动发送消息，系统推送无法推送自身
        sendImAdd3DLocalMessage(
          activeQuickInfo?.currentPickNo || '',
          priceAndNum.total,
          betsList,
        );
        setBetsList([]);
        setBetVisble(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const onPaySubmitCheck = debounce(() => {
    onPaySubmit();
  }, 200);

  const onChangeTime = (i: number) => {
    if (i === activeIndex) {
      return;
    }
    if (betsList.length) {
      setIsDel(false);
      setTimeout(() => {
        confirmShow(
          'Confirmation Reminder',
          <Text>
            {i18n.t('game-page.change-bets.p1')}
            {'\n'}
            <Text color={theme.basicColor.dark} fontFamily="fontInterBold">
              {activeQuickInfo?.timeName}
            </Text>{' '}
            {i18n.t('bets-detail.label.time').toLowerCase()}.{'\n'}
            {i18n.t('game-page.change-bets.p2')}
          </Text>,
          () => {
            setBetsList([]);
            setActiveIndex(i);
            if (pickGameType === 'quick') {
              setTimeout(() => {
                getQuickConfig(i).then();
              }, 0);
            }
          },
        );
      }, 0);
    } else {
      setActiveIndex(i);
      if (pickGameType === 'quick') {
        getQuickConfig(i).then();
      }
    }
  };

  const levelList = React.useMemo(() => {
    return quickData?.pickInfos || [];
  }, [quickData]);

  const activeQuickInfo = React.useMemo<PickGamesContent | undefined>(() => {
    if (quickQueryList && quickQueryList.length > 0) {
      return quickQueryList[activeIndex];
    }
  }, [quickQueryList, activeIndex]);

  React.useEffect(() => {
    if (index === 1 && totalOrder.current) {
      totalOrder.current = 0;
      setOrderNum(0);
    }
  }, [index]);
  const picker = useRef<any>();
  const [tabKey, setTabKey] = useState(
    pickGameType === 'normal' ? 'result' : 'chat',
  );
  const handlerTabChange = (i: number, k: string) => {
    setIndex(i);
    setTabKey(k);
  };
  const [betVisible, setBetVisble] = useState(false);
  const [remingTime, setRemingTime] = useState(60);
  const closeBetModal = () => {
    setBetVisble(false);
  };
  const handleTimeChange = (val: number) => {
    setRemingTime(val);
  };
  const {render} = useRenderRemainTime({
    remainMillis: remingTime,
    icon: 24,
  });
  const onClear = () => {
    setIsDel(true);
    setBetsList([]);
  };
  const [isChat, setChat] = useState(false);
  const handleChat = useCallback((visible: boolean) => {
    setChat(visible);
  }, []);
  const betRef = useRef<any>();

  return (
    <div className="bg-[#EFF1F7] flex flex-col h-full">
      <QuickHeader
        selectName={
          quickQueryList?.[activeIndex].timeName
            ? 'Quick 3D-' + quickQueryList?.[activeIndex].timeName
            : 'Quick 3D'
        }
        onChange={() => {
          picker.current.open();
        }}
      />

      <Spin
        loading={false}
        // loading={loading}
        style={[
          theme.flex.flex1,
          theme.overflow.hidden,
          // {paddingBottom: calcActualSize(56)},
        ]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <QuickDigitPicker
            ref={picker}
            activeIndex={activeIndex}
            onItemSelect={onChangeTime}
            list={quickQueryList}
          />
          <QuickDigit
            remainingTime={activeQuickInfo?.drawTimeLong || 0}
            timeVersion={timeVersion}
            showRule={() => setRuleVisible(true)}
            list={quickQueryList}
            activeInfo={activeQuickInfo}
            activeIndex={activeIndex}
            handleTimeChange={handleTimeChange}
            onChangeTime={onChangeTime}
            onDisabled={() => {
              setDisabled(true);
              drawerRef.current?.close();
            }}
            isSameRoute={currentRouteName === 'Digit'}
            stopBetSec={activeQuickInfo?.stopBetSec || 30}
            onTimeEnd={() => {
              getQuickConfig(activeIndex);
            }}
          />
          <DigitTab
            orderNum={orderNum}
            type={pickGameType}
            setIndex={handlerTabChange}
            index={index}
            pickThreeId={activeQuickInfo?.pickThreeID}
          />
          <QuickTabView
            refreshCode={refreshCode}
            type={pickGameType}
            pickThreeId={activeQuickInfo?.pickThreeID}
            tabKey={tabKey}
          />
          {tabKey === 'chat' && (
            <ChatBottom
              disabled={
                remingTime <= (activeQuickInfo?.stopBetSec || 30) * 1000
              }
              handleChat={handleChat}
              handlePlay={() => {
                setBetVisble(true);
                setBetsList([]);
              }}
            />
          )}
          {tabKey === 'chat' && isChat && (
            <ChatBottomSend onClose={() => handleChat(false)} />
          )}
        </View>
      </Spin>
      <Drawer
        beforeClose={() => {}}
        ref={drawerRef}
        mode="bottom"
        contentBackgroundColor="#0000">
        <BetsDigit
          digitList={betsList}
          onDigitClose={_i => {
            const copy = [...betsList];
            copy.splice(_i, 1);
            setBetsList(copy);
          }}
          onClose={() => {
            if (betsList.length === 0) {
              drawerRef.current?.close();
            } else {
              setIsDel(true);
              setTimeout(() => {
                confirmShow(
                  i18n.t('label.del'),
                  'Please confirm you want to clear all numbers?',
                  () => {
                    setBetsList([]);
                  },
                );
              }, 0);
            }
          }}
        />
      </Drawer>
      {betVisible ? (
        <BetModal
          stopBetSec={activeQuickInfo?.stopBetSec || 30}
          isEmpty={false}
          ref={betRef}
          remingTime={remingTime}
          headerTitle={
            <div className="absolute w-full flex items-center justify-center px-3 -top-[36px]">
              {render()}
              <div className="absolute right-[1rem] flex gap-3">
                <HowPlaySvg
                  width={32}
                  height={32}
                  onClick={() => setRuleVisible(true)}
                />
                <CloseSvg
                  width={32}
                  height={32}
                  onClick={() => betRef.current?.closeModal()}></CloseSvg>
              </div>
            </div>
          }
          onClose={closeBetModal}>
          <ScrollView
            style={theme.flex.flex1}
            contentContainerStyle={{paddingBottom: 20}}>
            {levelList.map((item, _i) => (
              <div className="first:mt-0 px-3">
                <div key={_i} className="rounded-lg bg-white mt-2">
                  <BetsDigitLevelItem
                    onAdd={(t, v, c, startElement) => {
                      parabolaService.start({
                        startWith: startElement!,
                        endWith: endElement.current!,
                      });
                      handleAdd({
                        type: t,
                        value: v,
                        count: c,
                        level: item.pickLevel,
                        basePrice: item.pickAmount,
                        pickInfoId: item.pickInfoId,
                      });
                    }}
                    handleBox={(v, c, startElement) => {
                      parabolaService.start({
                        startWith: startElement!,
                        endWith: endElement.current!,
                      });
                      handleBox({
                        value: v,
                        count: c,
                        basePrice: item.pickAmount,
                        pickInfoId: item.pickInfoId,
                      });
                    }}
                    winPrize={item.pickWinAmount}
                    payment={item.pickAmount}
                    level={item.pickLevel}
                  />
                </div>
              </div>
            ))}
          </ScrollView>
          <ShopCart
            endRef={endElement}
            tip={`${priceAndNum.num} ${i18n.t('game-page.label.numbers')}`}
            price={priceAndNum.total}
            disable={betsList.length === 0 || disabled}
            onConfirm={onPaySubmitCheck}
            onClear={onClear}
            title={i18n.t('game-page.label.myNum')}>
            <ScrollView
              style={[theme.margin.topl]}
              contentContainerStyle={[
                theme.flex.row,
                theme.padding.lrl,
                theme.padding.tops,
                // eslint-disable-next-line react-native/no-inline-styles
                {flexWrap: 'wrap', gap: 12},
                theme.background.white,
              ]}>
              {betsList.map((item, _i) => (
                <BetsItem
                  onClose={() => {
                    const copy = [...betsList];
                    copy.splice(_i, 1);
                    setBetsList(copy);
                  }}
                  type={item.type}
                  digit={item.value}
                  rate={item.count}
                  key={_i}
                />
              ))}
            </ScrollView>
          </ShopCart>
        </BetModal>
      ) : null}

      <RuleModal
        title="Rule"
        visible={ruleVisible}
        scrollEnabled={false}
        onClose={() => setRuleVisible(false)}>
        <DigitRule
          gameName={
            pickGameType === 'quick'
              ? `Quick3D ${activeQuickInfo?.cycle}min`
              : pickName || ''
          }
        />
      </RuleModal>
      {renderConfirmModal}
      <GoldWin
        amount={winAmount}
        visible={showWin}
        onClose={() => {
          setShowWin(false);
        }}
      />
      <Video
        onEnd={() => {
          setWinPaused(true);
          // videoRef.current?.seek(0);
        }}
        audioOnly
        source={require('@components/assets/music/win.mp3')}
        src={'/assets/mp3/win.mp3'}
        paused={winPaused}
      />
    </div>
  );
};

export default QuickGame;
