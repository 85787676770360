import React from 'react';
import {View, Image} from 'react-native';
import theme from '@/style';
import animationjson from './data.json';
import LoadingText from './LoadintText';

const SportSpin = ({
  loading,
  children,
}: {
  loading: boolean;
  children?: React.ReactNode;
}) => {
  const viewRef = React.useRef(null);
  React.useEffect(() => {
    if (!loading) {
      return;
    }
    if (document && viewRef.current) {
      const lottie = require('lottie-web');
      // @ts-ignore
      const animation = lottie.loadAnimation({
        container: viewRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationjson,
      });
      return () => {
        animation.destroy();
      };
    }
  }, [loading]);
  return (
    <View style={[theme.flex.flex1, theme.flex.flex, theme.flex.col]}>
      {loading && (
        <View style={[theme.flex.center, theme.fill.fill]}>
          <Image
            source={require('./sport.webp')}
            resizeMode="contain"
            style={{
              width: 401 / 2,
              height: 209 / 2,
              marginBottom: -30,
            }}
          />
          <View
            ref={viewRef}
            style={[
              {
                height: 593 / 2,
                width: 482 / 2,
              },
            ]}
          />
          <LoadingText
            style={{
              fontSize: 20,
              width: 148,
              marginTop: -20,
              color: theme.fontColor.secAccent,
              alignSelf: 'center',
            }}
          />
        </View>
      )}
      {children}
    </View>
  );
};

export default SportSpin;
