import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import LinearGradient from '@/components/basic/linear-gradient';
import Text from '@/components/basic/text';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import theme from '@/style';
import {goTo} from '@/utils';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet, Pressable, View} from 'react-native';

const style = StyleSheet.create({
  tabItem: {
    height: 72,
  },
  tabItemActive: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.basicColor.primary,
    paddingTop: 2,
    position: 'relative',
    top: -6,
  },
  tabItemBgActive: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    paddingTop: 4,
  },
});

const HomeTab = ({
  value,
  setValue,
  isA,
}: {
  value: 0 | 1 | 2 | 3 | 4 | 5;
  setValue: (v: 0 | 1 | 2 | 3 | 4 | 5) => void;
  isA: boolean;
}) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();

  const tabList = React.useMemo(
    () =>
      isA
        ? [
            {
              img: require('@assets/imgs/home/hot.webp'),
              title: i18n.t('home.tab.hot'),
              onPress: () => {
                setValue(0);
              },
            },
            {
              img: require('@assets/imgs/home/live.png'),
              title: i18n.t('home.tab.live'),
              onPress: () => {
                setValue(1);
              },
            },
            {
              img: require('@assets/imgs/home/casino.png'),
              title: i18n.t('home.tab.casino'),
              onPress: () => {
                setValue(2);
              },
            },
            {
              img: require('@assets/imgs/home/sports.webp'),
              title: i18n.t('home.tab.sports'),
              onPress: () => {
                goTo('Sports');
              },
            },
            {
              img: require('@assets/imgs/home/scratch-off.webp'),
              title: i18n.t('home.tab.scratchOff'),
              onPress: () => {
                setValue(4);
              },
            },
            {
              img: require('@assets/imgs/home/lottery.webp'),
              title: i18n.t('home.tab.lottery'),
              onPress: () => {
                setValue(5);
              },
            },
          ]
        : [
            {
              img: require('@assets/imgs/home/lottery.webp'),
              title: i18n.t('home.tab.lottery'),
              onPress: () => {
                setValue(0);
              },
            },
            {
              img: require('@assets/imgs/home/scratch-off.webp'),
              title: i18n.t('home.tab.scratchOff'),
              onPress: () => {
                setValue(1);
              },
            },
            {
              img: require('@assets/imgs/home/live.png'),
              title: i18n.t('home.tab.live'),
              onPress: () => {
                setValue(2);
              },
            },
            {
              img: require('@assets/imgs/home/casino.png'),
              title: i18n.t('home.tab.casino'),
              onPress: () => {
                setValue(3);
              },
            },
            {
              img: require('@assets/imgs/home/sports.webp'),
              title: i18n.t('home.tab.sports'),
              onPress: () => {
                goTo('Sports');
              },
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isA],
  );

  return (
    <LinearGradient
      colors={['#fff', '#f4f5f8']}
      style={[
        theme.background.white,
        theme.padding.tops,
        theme.flex.row,
        theme.flex.between,
        isA ? theme.padding.lrs : theme.padding.lrl,
      ]}>
      {tabList.map((v, i) => {
        return (
          <ExposureWrap key={v.title} message={`HOME_TABS_${i + 1}_EXPOSURE`}>
            <Pressable
              style={[
                theme.position.rel,
                theme.overflow.hidden,
                // style.tabItem,
                value === i && [style.tabItemActive],
              ]}
              onPress={() => {
                trackClick(`HOME_TABS_${i + 1}_TAB`);
                v.onPress();
              }}>
              <LinearGradient
                colors={
                  value === i
                    ? ['rgb(136,212,140)', '#f4f5f8']
                    : ['#0000', '#0000']
                }
                style={[
                  theme.flex.flex1,
                  value === i && style.tabItemBgActive,
                ]}>
                <View style={[theme.padding.lrxs, theme.flex.centerByCol]}>
                  <Image
                    style={{
                      width: calcActualSize(48),
                      height: calcActualSize(48),
                    }}
                    source={v.img}
                  />
                  <Text
                    fontFamily="fontInterBold"
                    style={{fontSize: calcActualSize(10)}}>
                    {v.title}
                  </Text>
                </View>
              </LinearGradient>
            </Pressable>
          </ExposureWrap>
        );
      })}
    </LinearGradient>
  );
};

export default HomeTab;
