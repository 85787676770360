import React from 'react';
import {ReactComponent as BonusIcon} from '@/assets/icons/promotions/bonus.svg';
import {Button} from 'antd-mobile';
import {ReactComponent as HistoryIcon} from '@/assets/icons/promotions/history.svg';
import {useTranslation} from 'react-i18next';
import styles from './bonus-header-item.module.scss';
import {goTo, toPriceStr} from '@/utils';
import globalStore from '@/services/global.state';
const BonusHeaderItem = ({
  balance = 0,
  bonus = 0,
}: {
  balance?: number;
  bonus?: number;
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-row bg-white/80 backdrop-blur-[0.625rem] border border-white rounded-lg px-4 py-3 gap-3 justify-between items-end">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center">
          <BonusIcon className="size-6" />
          <span className="ml-2 mr-3 text-black/50 text-xs font-bold capitalize">
            {t('promotionsV2.bonus')}
          </span>
          <Button
            className={`px-2 py-1 bg-white border border-image rounded ${styles['bonus-history-button']}`}
            onClick={() => {
              goTo('PromotionsV2History');
            }}>
            <HistoryIcon className="size-3 text-t3" />
            <span className="text-t2 text-xs">{t('promotionsV2.history')}</span>
          </Button>
        </div>
        <span className="text-[1.7875rem] text-[#4C1F00] font-din font-bold capitalize">
          <span className="text-[1.21875rem]">{globalStore.currency}</span>{' '}
          {toPriceStr(bonus, {showCurrency: false})}
        </span>
      </div>
      <div className="flex flex-col items-end gap-1">
        <span className="text-black/50 text-xs font-bold capitalize">
          {t('label.balance')}
        </span>
        <span className="text-t2 text-sm font-bold capitalize">
          <span className="text-xs">{globalStore.currency}</span>
          {toPriceStr(balance, {showCurrency: false})}
        </span>
      </div>
    </div>
  );
};

export default BonusHeaderItem;
