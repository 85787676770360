import {imHttp} from '@/utils';
export type RoomItem = {
  groupId: string;
  groupInfo: string; // 简介
  groupName: string;
  groupNotice: string; // 通知
  groupLogo: string;
  createTime: number;
  updateTime: number;
  gameCodes: string;
  fillPass: boolean; // 是否已经输入密码
  groupType: 1 | 2 | 3; // '类型 1公开 2私密 3频道'
  lastMsg: any;
  subscribers: number;
  unreadMsgCount: number; // 未读数
};
export const getGroupList = (userId = 'null') => {
  return imHttp.post<any, RoomItem[]>('/zgIm/groupList/' + userId);
};
// 获取话术
export type ReplyType = {
  content: string; // 内容
  sort?: number;
  remark?: string; // 备注
  otherUrl?: string;
  contentType?: 1 | 2; // 1话术 2UPI
  id?: string;
};
export const getQuickList = (type: 1 | 2 | 3) => {
  return imHttp.post<null, {content: ReplyType[]}>('/quickReply/getList', {
    status: 1,
    contentType: type,
    pageNo: 1,
    pageSize: 1000,
  });
};
