import {useFocusEffect, useRoute} from '@react-navigation/native';
import qs from 'qs';
import {useCallback, useEffect, useState} from 'react';

export interface BaseTabItem {
  tabKey: string;
}

interface LinkedTabsProps<T extends BaseTabItem> {
  routeParamKey: string;
  tabsData?: T[];
  onTabChange?: (key: string) => void;
}

export function useLinkedTabs<T extends BaseTabItem>({
  routeParamKey,
  onTabChange,
  tabsData,
}: LinkedTabsProps<T>) {
  const [tabKey, setTabKey] = useState<string>();
  const [isInit, setIsInit] = useState(false);
  const route = useRoute();
  const params = route.params as Record<string, string>;
  const handleTabChangeNotLink = useCallback(
    (key: string) => {
      setTabKey(key);
      onTabChange?.(key);
    },
    [onTabChange],
  );
  const handleTabChange = useCallback(
    (key: string) => {
      handleTabChangeNotLink(key);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {[routeParamKey]: _, ...restParams} = params || {};
      const pathname = window.location.pathname;

      window.history.replaceState(
        null,
        '',
        `${window.location.origin}${pathname}?${qs.stringify({
          ...restParams,
          [routeParamKey]: key,
        })}`,
      );
    },
    [handleTabChangeNotLink, routeParamKey, params],
  );

  useFocusEffect(
    useCallback(() => {
      setIsInit(true);
    }, []),
  );

  useEffect(() => {
    if (isInit && tabsData?.length) {
      const initTabKey = params?.[routeParamKey] as string;
      const hasTab = tabsData.some(item => item.tabKey === initTabKey);
      if (hasTab) {
        handleTabChangeNotLink(initTabKey);
      } else {
        handleTabChangeNotLink(tabsData[0].tabKey);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit, tabsData?.length]);

  return {
    tabKey,
    onTabChange: handleTabChange,
  };
}
