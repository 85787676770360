// src/components/basic/LoadingText/LoadingText.tsx
import React, {useEffect, useState} from 'react';
import {Text} from 'react-native';

const LoadingText = ({style}: {style?: any}) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => {
        if (prevDots.length >= 3) {
          return '';
        }
        return prevDots + '.';
      });
    }, 500); // 每500毫秒更新一次

    return () => clearInterval(interval);
  }, []);

  return <Text style={style}>Sports Loading{dots}</Text>;
};

export default LoadingText;
