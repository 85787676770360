import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
interface RewardsCardProps {
  data: string;
}
const RewardsCard = ({data}: RewardsCardProps) => {
  const {t} = useTranslation();
  const parsedData = useMemo(() => {
    try {
      const removeHTMLData = data.replace(/<[^>]*>?/gm, '\n');
      const splitData = removeHTMLData.split('\n').filter(item => item.trim());
      return splitData.map(item => item.split(','));
    } catch (error) {
      return [];
    }
  }, [data]);
  const firstRow = parsedData[0];
  const otherRows = parsedData.slice(1);
  return (
    <div className="mx-4 bg-white rounded-xl flex flex-col p-3 gap-3 mb-2">
      <div className="flex flex-row items-center gap-2">
        <div className="w-1 h-3 bg-c1" />
        <div className="text-t1 text-sm font-bold capitalize">
          {t('promotionsV2.eventRewards')}
        </div>
      </div>
      <div className={'flex flex-col flex-1 text-t2 text-sm font-bold'}>
        <table className="w-full">
          <thead className="text-xs bg-[#C6D5E1] capitalize">
            <tr>
              {firstRow.map((item, index) => (
                <th key={index} className="text-center py-2">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {otherRows.map((item, index) => (
              <tr
                key={index}
                className="bg-[#F3F9FF] odd:bg-[#E3F1FD] divide-x divide-[#C6D5E1]">
                {item.map((subItem, subIndex) => (
                  <td key={subIndex} className="text-center py-2">
                    {subItem}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RewardsCard;
