import {http, indusWinHttp, sportsHttp} from '@utils';
import {
  ScratchListItem,
  NoticeCheckList,
  PagerParams,
  SpinOrderList,
  OrderCreateParams,
  PrizeInfo,
  FreeLotteryInfo,
  BannerListItem,
  HotGameItem,
  KeralaListItem,
  DigitListItem,
  DiceListItem,
  MatkaListItem,
  LiveHotGameItem,
  HomeGameListParams,
  HomeGamePageInfo,
  HomeGameRedGreenItem,
  WinnerItem,
  DigitResultItem,
  KeralaResultItem,
  RankingData,
  CarListItem,
  SportItem,
  StateLotteryItem,
} from './home.type';
import {packageId} from '@/config';
import globalStore from '@/services/global.state';

/** 获取刮刮乐入口列表 */
export const getScratchList = () => {
  return indusWinHttp.get<null, ScratchListItem[]>(
    'iGaming/api/scratchoffGameList',
  );
};

/** 获取活动入口 */
export const getNoticeCheck = () => {
  return http.post<null, NoticeCheckList>('app/sys/notice/check');
};

/** 顶部banner */
export const getBannerList = (type = 3) => {
  return http.post<{type: number}, BannerListItem[]>(
    'app/banner/manage/new/list',
    {
      type,
    },
  );
};

/** 热门小游戏 */
export const getHotGgmeList = () => {
  return http.post<null, HotGameItem[]>('app/hotGame/list');
};

/** kerala列表 */
export const getKeralaList = () => {
  return http.post<{type: number}, KeralaListItem[]>(
    'app/lottery/type/kerala/list/new',
    // TODO 这里的渠道是临时解决,需要后端调整逻辑
    {type: 1, channel: 'h5'},
  );
};
/** kerala结果列表 */
export const getKeralaResultList = () => {
  return http.post<null, KeralaResultItem[]>(
    '/app/lottery/result/getResultHomeList',
  );
};

/** 3Digit列表 */
export const getDigitList = () => {
  return http.post<null, DigitListItem[]>('app/pick/info/list');
};
/** 3Digit结果列表 */
export const getDigitResultList = () => {
  return http.post<null, DigitResultItem[]>(
    '/app/pick/result/lastResultHomeList',
  );
};

/** Dice列表 */
export const getDiceList = () => {
  return http.post<null, DiceListItem[]>('app/diceThree/homeList');
};

/** Matka列表 */
export const getMatkaList = () => {
  return http.post<null, MatkaListItem[]>('app/matka/lottery/home/list');
};

export const postSpinOrderList = (params: PagerParams) => {
  return http.post<PagerParams, SpinOrderList>(
    'app/turntable/order/list',
    params,
  );
};

export const postSpinOrderCreate = (count: number) => {
  return http.post<OrderCreateParams, PrizeInfo>('app/turntable/order/create', {
    isDemo: 1,
    count,
    betAmount: 10 * count,
  });
};

export const postRankingList = () => {
  return http.post<null, RankingData>('app/homeGames/getRankingData');
};

export const postGetFreeLottery = () => {
  return http.post<null, FreeLotteryInfo>('app/share/getFreeKeralaTickets');
};

export const getLiveHotGameList = () => {
  return indusWinHttp.get<null, LiveHotGameItem[]>('iGaming/liveHotGameList');
};

/**
 * 首页视讯游戏列表
 * categoryId live是6
 */
export function getHomeGames(params: HomeGameListParams) {
  return http.post<HomeGameListParams, HomeGamePageInfo>(
    'app/homeGames/getList',
    params,
  );
}

export interface HomeGameCardItem {
  categoryId: number;
  // 				"packageId":5,
  // 				"sort":1,
  // 				"twoCategoryId":22
  // 游戏图片
  gamePic: string;
  otherUrl: string;
  otherParams: string;
  // 游戏价格
  gamePrice: string;
  // 具体游戏类型 Lottery类型分dice和color，其他楼层类型一样
  gameType: string; // "Dice"
  // 游戏url，对于dice和color和scratch，需要自行配置url进行匹配，
  gameUrl: string; // "configId=16"
  // 游戏名称
  name: string; //"Dice 1 minute"
  // 游戏厂商
  provider: string; // "SKY GAME"
  // 厂商独立游戏编号，比如SA厂商使用的defaultTable
  tripartiteUniqueness: string;
  // 游戏id，Slotegrator厂商使用这个拿到链接
  id: number;
  // 游戏厂商 判断跳转的使用这个
  source: string; // "SKY GAME"
  grandPrize: number;
  maxBetting: number;
  maxBonus: number;
  playersNumber: number;
  picPlaceholder?: number;
}
export interface HomeGameFloorItem {
  // 楼层icon图片
  categoryPic: string;
  gamesList: HomeGameCardItem[];
  // 楼层标题
  name: string;
  // 楼层唯一key
  uniqueKey: string; // hotGames lotteryV2 indianCasino liveCasinoV2 Slots New Games
  id: number;
  currentTimestamp?: number;
}
export const getHomeHotGames = () => {
  return http.post<{position: number}, HomeGameFloorItem[]>(
    'app/homeGamesCategory/getAllListBySortV2',
    {
      position: 6,
    },
  );
};

// 获取游戏平台或厂商 categoryId live是6
export function getDistinctFieldValues(
  fieldName: 'provider' | 'source' | 'game_type',
  categoryId: string,
) {
  return http.get<{categoryId: string; fieldName: string}, string[]>(
    `app/homeGames/getDistinctFieldValues?packageId=${packageId}&fieldName=${fieldName}&categoryId=${categoryId}`,
  );
}

/** 获取首页红绿灯列表 */
export const getHomeRedGreenList = () => {
  return http.post<null, HomeGameRedGreenItem[]>(
    'app/redGreen/lottery/homeList',
    {
      gameType: 1,
    },
  );
};

export const getBiggestWinnerList = () => {
  return http.post<null, WinnerItem[]>('app/homeGames/getBiggestList');
};

export const getJackpotWinnertList = () => {
  return http.post<null, WinnerItem[]>('app/homeGames/getJackpotList');
};

export type LotteryData = {
  currentTimestamp: number;
  diceResList: DiceListItem[];
  redResList: HomeGameRedGreenItem[];
  matkaResList: MatkaListItem[];
  keralaResList: KeralaListItem[];
  pickResList: DigitListItem[];
  stateLotteryList: StateLotteryItem[];
};
export const getHomeGameList = () => {
  return http.get<null, LotteryData>('app/home/game/list/2');
};

export interface CarGameListParams {
  gameName?: string;
  sort?: number;
}

export interface CarPageInfo {
  totalPages: number;
  totalSize: number;
  content: CarListItem[];
}

export function getCarGameList() {
  return http.post<CarGameListParams, CarPageInfo>('app/homeGames/getList', {
    categoryId: 15,
  });
}

export const getSportGames = () => {
  return http.post<{groupCode: string}, SportItem[]>(
    'app/moduleTypes/getAllListBySort',
    {
      groupCode: 'SPORT_GAME',
    },
  );
};

export const getGameURL = (gameType: string) => {
  return sportsHttp.get<string, string>(
    `sportGame/getGameUrl?gameType=${gameType}`,
  );
};

export const getBetBy = () => {
  return indusWinHttp.get<null, {brandId: string; token: string}>(
    `sport/betby/getToken?lang=${
      globalStore.lang ? globalStore.lang.split('_')[0] : 'en'
    }`,
  );
};
