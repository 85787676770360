import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
interface RewardsCardProps {
  currentCount: number;
  data: string;
}
const RewardsCard = ({currentCount, data}: RewardsCardProps) => {
  const {t} = useTranslation();
  const parsedData = useMemo(() => {
    const removeHTMLData = data.replace(/<[^>]*>?/gm, '\n');
    const splitData = removeHTMLData.split('\n').filter(item => item.trim());
    return splitData.map(item => item.split(','));
  }, [data]);
  return (
    <div className="mx-4 bg-white rounded-xl flex flex-col p-3 gap-3">
      <div className="flex flex-row items-center gap-2">
        <div className="w-1 h-3 bg-c1" />
        <div className="text-t1 text-sm font-bold capitalize">
          {t('promotionsV2.eventRewards')}
        </div>
      </div>
      <div className={'flex flex-col flex-1 text-t2 text-sm font-bold'}>
        <div className="flex flex-row text-xs bg-[#C6D5E1]">
          <div className="w-[5.5rem] text-center py-2">
            {t('promotionsV2.vipLevel')}
          </div>
          <div className="flex-1 text-center py-2">
            {t('promotionsV2.rechargeAmount')}
          </div>
          <div className="flex-1 text-center py-2">
            {t('promotionsV2.rechargeBonus')}
          </div>
        </div>
        {parsedData.map((item, index) => (
          <div
            className={`flex flex-row ${
              currentCount === index
                ? 'bg-[#FFE4D8]'
                : 'bg-[#F3F9FF] odd:bg-[#E3F1FD]'
            } divide-x divide-[#C6D5E1]`}
            key={`${index}`}>
            <div className="w-[5.5rem] text-center py-2">{item[0]}</div>
            <div className="flex-1 text-center py-2">{item[1]}</div>
            <div className="flex-1 text-center py-2">{item[2]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RewardsCard;
