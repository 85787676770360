import React, {useEffect, useState} from 'react';
import PromotionCard from './promotion-card';
import {useLoadMore} from '@/common-pages/hooks/load-more.hooks';
import {getPromotionList} from '@/common-pages/promotion/promotion.service';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {InfiniteScroll} from 'antd-mobile';
import {useRequest} from 'ahooks';
import {useTranslation} from 'react-i18next';
import {getUserGroupList} from './service';
import {useLogin} from '@/utils/state-hooks';
import NoData from '@/components/basic/error-pages/no-data';
const Promotion = () => {
  const [pageSize, setPageSize] = useState(10);
  const {t} = useTranslation();
  const {loading, list, loadMore, hasMore, reload} = useLoadMore(pageNo => {
    return getPromotionList(pageNo).then(res => {
      if (pageNo === 1) {
        setPageSize(res.content.length);
      }
      return {
        list: res.content,
        total: res.totalSize,
      };
    });
  }, pageSize);
  const login = useLogin();

  const {data: userGroupList} = useRequest(() => getUserGroupList());

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin style={[theme.flex.flex1, theme.flex.col]} loading={loading}>
      <div className="flex flex-1 flex-col gap-2 py-3 px-2 overflow-auto">
        {userGroupList?.map(item => (
          <PromotionCard
            key={item.id}
            type={item.type || 'Promotion'}
            expired={false}
            bg={item.displayImg}
            login={login}
            id={item.id}
            joined={item.participantsCount}
          />
        ))}
        {list.length > 0 &&
          list.map((item, index) => (
            <PromotionCard
              key={item.id}
              type={item.activityType || 'Promotion'}
              expired={false}
              oldPromotion={true}
              oldItem={item}
              bg={item.activityIcon}
              joined={100000 + ((index * 31337 + 33829) % 100000)}
              login={login}
            />
          ))}
        {(userGroupList && userGroupList.length > 0) || list.length > 0 ? (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            {hasMore ? t('label.loading') : t('label.noMore')}
          </InfiniteScroll>
        ) : (
          <NoData />
        )}
      </div>
    </Spin>
  );
};

export default Promotion;
