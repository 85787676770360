import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import React, {useCallback, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import RightMenusService from './right-menus-service';
import RightMenusTop from './right-menus-top';
import HomeSideBonus from '@/pages/home/components/home-side-bonus';
import {useLogin} from '@/utils/state-hooks';
import {useFocusEffect} from '@react-navigation/native';
import {getTaskCount} from '@/pages/promotions/service';
import {goTo} from '@/utils';

export interface RightMenusProps {
  hasTop?: boolean;
  hasBonus?: boolean;
  onTop?: () => void;
}

const RightMenus: React.FC<RightMenusProps> = ({hasTop, onTop, hasBonus}) => {
  const {calcActualSize} = useScreenSize();
  const rightMenusStyle = StyleSheet.create({
    main: {
      right: calcActualSize(22),
      bottom: calcActualSize(24),
    },
  });

  const [taskCount, setTaskCount] = useState(0);
  const login = useLogin();

  useFocusEffect(
    useCallback(() => {
      if (!login) {
        return;
      }
      getTaskCount().then(data => {
        setTaskCount(data);
      });
    }, [login]),
  );

  const handleBonusClick = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('PromotionsV2', {tabKey: 'bonus'});
  };

  return (
    <View
      style={[
        theme.position.abs,
        rightMenusStyle.main,
        theme.flex.col,
        theme.flex.centerByCol,
        {
          gap: calcActualSize(12),
        },
      ]}>
      <RightMenusService />
      {hasBonus ? (
        <HomeSideBonus count={taskCount} onClick={handleBonusClick} />
      ) : null}
      {hasTop ? <RightMenusTop onClick={onTop} /> : null}
    </View>
  );
};

export default RightMenus;
