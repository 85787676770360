import React, {useState} from 'react';
import NavTitle from './nav-title';
import Bonus from './bonus';
import Promotion from './promotion';

const Promotions = () => {
  const [activeTab, setActiveTab] = useState('');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <div className="flex flex-col h-screen w-full overflow-hidden">
      <NavTitle onTabChange={handleTabChange} />
      {activeTab === 'bonus' && <Bonus />}
      {activeTab === 'promotion' && <Promotion />}
    </div>
  );
};

export default Promotions;
