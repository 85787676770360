import {Image, Modal} from 'antd-mobile';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import BackgroundTimer from '@components/utils/timer';
export interface CurrentRechargeProps {
  // 当前用户已经充值次数，可以为-1，这情况代表未登录
  currentCount: number;
  eventRewards: string;
}

interface TabItemProps {
  icon: string;
  activeIcon: string;
  // 当前用户已经充值次数
  currentCount: number;
  // 该item代表哪个充值次数
  itemCount: number;
  amountRange: string;
  bonus: string;
  login: boolean;
}

const TabItem = ({
  icon,
  activeIcon,
  currentCount,
  itemCount,
  amountRange,
  bonus,
  login,
}: TabItemProps) => {
  const [visible, setVisible] = useState(false);

  const {t} = useTranslation();
  const modalData = useMemo(() => {
    if (!login) {
      return {
        title: t('promotionsV2.tip.notLogin'),
      };
    }
    if (currentCount + 1 > itemCount) {
      return {
        title: t('promotionsV2.tip.completedRecharge', {
          times: t(`promotionsV2.times.${itemCount}`),
        }),
      };
    }
    if (currentCount + 1 === itemCount) {
      return {
        title: t('promotionsV2.tip.currentRechargeTitle', {
          times: t(`promotionsV2.times.${itemCount}`),
        }),
        content: t('promotionsV2.tip.rechargeToGet', {
          amountRange: amountRange,
          bonus: bonus,
        }),
      };
    }
    return {
      title: t('promotionsV2.tip.notCompleteRechargeTitle', {
        times: t(`promotionsV2.times.${itemCount}`),
      }),
      content: t('promotionsV2.tip.mustComplete', {
        currentCount: t(`promotionsV2.times.${itemCount - 1}`),
        nextCount: t(`promotionsV2.times.${itemCount}`),
      }),
    };
  }, [itemCount, currentCount, t, amountRange, bonus, login]);
  const [time, setTime] = useState(3);
  const timer = useRef<number>();
  const handleClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    if (visible) {
      setTime(3);
      timer.current = BackgroundTimer.setInterval(() => {
        setTime(v => v - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        BackgroundTimer.clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [visible]);
  useEffect(() => {
    if (time <= 0) {
      handleClose();
    }
  }, [time]);
  return (
    <>
      <div
        className={`flex-1 ${currentCount + 1 > itemCount ? 'opacity-30' : ''}`}
        onClick={() => {
          setVisible(true);
        }}>
        <Image
          className="w-full h-12"
          src={currentCount + 1 === itemCount ? activeIcon : icon}
          fit="fill"
        />
      </div>
      <Modal
        bodyClassName="bg-transparent"
        visible={visible}
        onClose={handleClose}
        showCloseButton={false}
        closeOnMaskClick
        content={
          <div className="flex flex-col items-center justify-center gap-2 text-white bg-black/70 w-[18.5rem] p-6 rounded-xl">
            <p className="font-bold text-base text-center">{modalData.title}</p>
            {!!modalData.content && (
              <p className="text-sm text-center">{modalData.content}</p>
            )}
          </div>
        }
      />
    </>
  );
};

const icons = [
  {
    icon: require('@/assets/icons/promotions/recharge-1.webp'),
    activeIcon: require('@/assets/icons/promotions/recharge-1-active.webp'),
  },
  {
    icon: require('@/assets/icons/promotions/recharge-2.webp'),
    activeIcon: require('@/assets/icons/promotions/recharge-2-active.webp'),
  },
  {
    icon: require('@/assets/icons/promotions/recharge-3.webp'),
    activeIcon: require('@/assets/icons/promotions/recharge-3-active.webp'),
  },
  {
    icon: require('@/assets/icons/promotions/recharge-4.webp'),
    activeIcon: require('@/assets/icons/promotions/recharge-4-active.webp'),
  },
];

const CurrentRecharge = ({
  currentCount,
  eventRewards,
}: CurrentRechargeProps) => {
  const {t} = useTranslation();
  const parsedData = useMemo(() => {
    const removeHTMLData = eventRewards.replace(/<[^>]*>?/gm, '\n');
    const splitData = removeHTMLData.split('\n').filter(item => item.trim());
    return splitData.map(item => item.split(','));
  }, [eventRewards]);
  return (
    <div className="flex flex-col gap-2 py-2 px-4">
      <span className="text-[#EFA75F] text-sm font-bold">
        {t('promotionsV2.yourCurrentRecharge')}
      </span>
      <div className="flex flex-row items-center gap-1">
        {icons.map((item, index) => (
          <TabItem
            key={index}
            icon={item.icon}
            activeIcon={item.activeIcon}
            currentCount={currentCount}
            itemCount={index + 1}
            amountRange={parsedData?.[index]?.[1] || ''}
            bonus={parsedData?.[index]?.[2] || ''}
            login={currentCount > -1}
          />
        ))}
      </div>
    </div>
  );
};

export default CurrentRecharge;
