import React, {useMemo} from 'react';
import {Button, Image} from 'antd-mobile'; // 引入antd-mobile的Image组件
import {ReactComponent as QuestionIcon} from '@/assets/icons/promotions/question.svg';
import {ReactComponent as FireIcon} from '@/assets/icons/promotions/fire.svg';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import {goTo, goWhatsAppChat} from '@/utils';
import {goToUrl} from '@/common-pages/game-navigate';
import {PromotionListItem} from '@/common-pages/promotion/promotion.service';
import {getActivityInfo, getActivityProgress} from './service';
interface PromotionCardProps {
  id?: number;
  type: string;
  joined?: number;
  bg?: string;
  expired: boolean;
  oldPromotion?: boolean;
  oldItem?: PromotionListItem;
  login: boolean;
}

const goOldPromotion = (url: string = '', title: string = '') => {
  if (url.startsWith('http')) {
    goToUrl(url, title);
  } else if (url.startsWith('gameType')) {
    const game = url.split('=')[1];
    // ToDo 无默认值这里点击按钮无效
    game && goTo(game);
  } else {
    // ToDo 无默认值这里点击按钮无效
    goWhatsAppChat(url.split(/[^\d]+/).find(s => s.length === 10));
  }
};

const PromotionCard = ({
  id,
  type,
  expired,
  bg = '/',
  joined = 134252,
  oldPromotion = false,
  oldItem,
  login,
}: PromotionCardProps) => {
  const {t} = useTranslation();
  const cardBg = useMemo(() => {
    return bg;
  }, [bg]);

  const typeStr = useMemo(() => {
    return type ? type.toLowerCase().split('_').join(' ') : '';
  }, [type]);

  const handleJoin = () => {
    if (expired) {
      globalStore.globalTotal.next({
        type: 'weak',
        message: t('promotionsV2.tip.expired'),
      });
      return;
    }
    if (oldPromotion) {
      if (oldItem && oldItem.activityUrl) {
        goOldPromotion(oldItem.activityUrl);
      } else if (
        oldItem &&
        oldItem.activityContent &&
        oldItem.activityContent.length > 0
      ) {
        globalStore.asyncSetItem('promotion-detail', JSON.stringify(oldItem));
        goTo('PromotionDetail');
      }
      return;
    }
    console.log('id', id);
    if (!id) {
      return;
    }
    getActivityInfo({activityId: id, language: globalStore.lang}).then(res => {
      console.log('res', res);
      const data = JSON.stringify(res);
      localStorage.setItem('promotion-detail-v2', data);
      if (res.activityInfo?.templateKey?.startsWith('COMMON')) {
        goTo('PromotionsV2Common');
      } else if (
        ['RECHARGE', 'WITHDRAW', 'BET'].some(
          item => res.activityInfo?.templateKey?.split('_')[1] === item,
        ) &&
        ['AMOUNT', 'COUNT'].some(item =>
          res.activityInfo?.templateKey?.split('_')[2].startsWith(item),
        )
      ) {
        if (login) {
          getActivityProgress(id).then(progress => {
            const progressData = JSON.stringify(progress);
            localStorage.setItem('promotion-progress-v2', progressData);
            goTo('PromotionsV2Cumulate');
          });
        } else {
          localStorage.removeItem('promotion-progress-v2');
          goTo('PromotionsV2Cumulate');
        }
      } else if (res.activityInfo?.templateKey?.startsWith('RECHARGE_REBATE')) {
        goTo('PromotionsV2RechargeRebate');
      } else {
        // 其他模板是其他的详情
        // goTo('PromotionsV2Detail');
      }
    });
    // const redirectMap: Record<string, () => void> = {
    //   RECHARGE: () => goTo('Recharge'),
    //   WITHDRAW: () => goTo('Withdraw'),
    // };
    // if (type && redirectMap[type]) {
    //   redirectMap[type]();
    // }
  };

  return (
    <div className="relative flex h-[13rem] flex-col rounded-xl overflow-hidden pb-3 flex-none">
      <Image
        src={cardBg}
        alt="-"
        className={'absolute inset-0 h-full w-full bg-image'}
        fallback={<div className="bg-image w-full h-full" />}
        fit="fill" // 设置图片填充模式
      />
      <div className="z-[1] flex justify-between items-end pr-3">
        <div className="rounded-br-[1rem] bg-white/50 px-3 py-1.5 text-t1 text-xs font-bold capitalize">
          {typeStr}
        </div>
        {false && (
          <Button className="p-0 bg-transparent">
            <QuestionIcon className="size-4 text-white" />
          </Button>
        )}
      </div>

      <div className="flex-1" />

      <div className="z-[1] flex flex-row justify-between items-center px-4">
        {oldPromotion ? (
          <div className="flex items-center text-white gap-1">
            <FireIcon className="size-3" />
            <span>
              {t('promotionsV2.joined', {num: joined.toLocaleString('en-US')})}
            </span>
          </div>
        ) : (
          <div />
        )}

        <Button
          className={`rounded-full bg-white text-t1 text-sm font-semibold py-2.5 w-[7.875rem] flex items-center justify-center before:rounded-full leading-[normal] ${
            expired ? '!opacity-30' : ''
          }`}
          onClick={handleJoin}>
          {expired ? t('promotionsV2.expired') : t('promotionsV2.join')}
        </Button>
      </div>
    </div>
  );
};

export default PromotionCard;
