import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Image, InfiniteScroll} from 'antd-mobile';
import BonusHeaderItem from './bonus-header-item';
import BonusItem from './bonus-item';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import {
  bonusUnComplete,
  cancelTask,
  getRewards,
  taskBonusPage,
} from './service';
import {useLoadMore} from '@/common-pages/hooks/load-more.hooks';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {downloadApk, goTo, goToByUri, inApp} from '@/utils';
import {BonusType, StatusType} from './type';
import ReceivedModal from './modal/received';
import GoToWithdrawModal from './modal/goto-withdraw';
import {useLogin} from '@/utils/state-hooks';
import NoData from '@/components/basic/error-pages/no-data';
import {useFocusEffect} from '@react-navigation/native';

const Bonus = () => {
  const {t} = useTranslation();
  const [balance, setBalance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const login = useLogin();

  const [receivedModal, setReceivedModal] = useState<{
    visible: boolean;
    bonus: number;
    bonusType: BonusType;
  }>({
    visible: false,
    bonus: 0,
    bonusType: 'ADD_BALANCE_AND_FROZEN',
  });

  const [goToWithdrawModal, setGoToWithdrawModal] = useState<{
    visible: boolean;
    bonus: number;
  }>({
    visible: false,
    bonus: 0,
  });

  const refreshAmount = useCallback(() => {
    if (login) {
      globalStore.amountCheckOut.next({});
      bonusUnComplete().then(res => {
        setBonus(+res);
      });
    } else {
      setBalance(0);
      setBonus(0);
    }
  }, [login]);

  useFocusEffect(
    useCallback(() => {
      refreshAmount();
      reload();
      const sub = globalStore.amountChanged.subscribe(({current}) => {
        setBalance(current);
      });
      return () => {
        sub.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login]),
  );

  const getTaskBonusPage = useCallback((pageNum: number, pageSize: number) => {
    return taskBonusPage(pageNum, pageSize).then(res => ({
      list: res.list,
      total: res.total,
    }));
  }, []);

  const {list, loading, loadMore, reload, hasMore} =
    useLoadMore(getTaskBonusPage);
  const [extraList, setExtraList] = useState<
    {
      progressStatus: string;
      rewardStatus: string;
    }[]
  >([]);

  useEffect(() => {
    if (list.length > 0) {
      setExtraList(
        list.map(item => ({
          progressStatus: item.progressStatus || '0',
          rewardStatus: item.rewardStatus || '0',
        })),
      );
    }
  }, [list]);

  const bonusList = useMemo(() => {
    return list.map((item, index) => ({
      ...item,
      progressStatus: extraList[index]?.progressStatus || 'PROGRESSING',
      rewardStatus: extraList[index]?.rewardStatus || '0',
    }));
  }, [list, extraList]);

  const handleClaimNow = useCallback(() => {
    goTo('Withdraw');
  }, []);

  return (
    <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
      <div className="flex flex-col flex-1 overflow-hidden bg-[#FFB98C] relative">
        <Image
          className="absolute top-0 left-0 w-full"
          src={require('@/assets/imgs/promotions/rewards-center.webp')}
          fallback={<div className="bg-[#FFB98C]" />}
        />
        <div className="flex flex-col flex-1 overflow-hidden z-[1] gap-2.5">
          <div className="mt-[6.5625rem] px-3">
            <BonusHeaderItem balance={balance} bonus={bonus} />
          </div>
          <div
            className={`flex flex-col flex-1 ${
              bonusList.length > 0 ? 'overflow-auto' : 'overflow-hidden'
            }`}>
            <div className="flex flex-col w-full gap-2.5 px-3">
              {bonusList.map((item, index) => (
                <BonusItem
                  key={index}
                  title={item.activityName}
                  currentAmount={item.progressValue}
                  totalAmount={item.targetValue}
                  multiple={item.multiple}
                  remain={[
                    item.countdownDays || 0,
                    item.countdownHours || 0,
                    item.countdownMinutes || 0,
                    item.countdownSeconds || 0,
                  ]}
                  bonusType={item.awardType as BonusType}
                  bonus={+(item.awardValue || 0)}
                  status={item.progressStatus as StatusType}
                  received={item.rewardStatus === 'REWARDED'}
                  onClimb={() => {
                    if (item.progressStatus === 'COMPLETED') {
                      if (!inApp) {
                        globalStore.globalTotal.next({
                          type: 'weak',
                          message: t('tip.receiveInApp'),
                        });
                        downloadApk();
                        return;
                      }
                      getRewards(item.taskProgressId || '0').then(res => {
                        if (res.rewardStatus) {
                          reload();
                          refreshAmount();
                        }
                        // if (res.jumpStatus) {
                        if (false) {
                          setGoToWithdrawModal(modal => ({
                            ...modal,
                            visible: true,
                            bonus: +(item.awardValue || 0),
                          }));
                        } else {
                          setReceivedModal({
                            visible: true,
                            bonus: +(item.awardValue || 0),
                            bonusType: item.awardType as BonusType,
                          });
                        }
                      });
                      return;
                    }
                    if (item.progressStatus === 'EXPIRED') {
                      globalStore.globalTotal.next({
                        type: 'weak',
                        message: t('promotionsV2.tip.expired'),
                      });
                      return;
                    }
                    goToByUri(
                      item.frontRedirectUri || 'route:Index?screen=Home',
                    );
                  }}
                  onDelete={() => {
                    if (!item.taskProgressId) {
                      return;
                    }
                    cancelTask(item.taskProgressId).then(() => {
                      globalStore.globalTotal.next({
                        type: 'success',
                        message: t('promotionsV2.tip.deleteSuccess'),
                      });
                      reload();
                      refreshAmount();
                    });
                  }}
                />
              ))}
              {bonusList.length > 0 ? (
                <InfiniteScroll
                  loadMore={loadMore}
                  hasMore={hasMore}
                  className="flex items-center justify-center mb-16">
                  {hasMore ? t('label.loading') : t('label.noMore')}
                </InfiniteScroll>
              ) : (
                <div className="flex flex-col h-[25rem] rounded-lg bg-white gap-3 p-3">
                  <span className="text-t1 text-sm font-bold">
                    {t('promotionsV2.taskList')}
                  </span>
                  <NoData label={t('error-pages.no-data.label.empty')} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ReceivedModal
        visible={receivedModal.visible}
        onClose={() => {
          setReceivedModal(modal => ({...modal, visible: false}));
        }}
        bonus={receivedModal.bonus}
        bonusType={receivedModal.bonusType}
      />
      <GoToWithdrawModal
        visible={goToWithdrawModal.visible}
        onClose={() => {
          setGoToWithdrawModal(modal => ({...modal, visible: false}));
        }}
        bonus={goToWithdrawModal.bonus}
        onClaimNow={handleClaimNow}
      />
    </Spin>
  );
};

export default Bonus;
