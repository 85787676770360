import React from 'react';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {ReactComponent as DigitClock} from '@/assets/icons/digit-clock.svg';

export default function useRenderRemainTime({
  remainMillis,
  size = 20,
  icon = 20,
}: {
  remainMillis: number;
  size?: number;
  icon?: number;
}) {
  const {calcActualSize} = useScreenSize();
  const remainTimeStr = React.useMemo(() => {
    const m = Math.floor((remainMillis % 3600000) / 60000);
    const s = Math.floor((remainMillis % 60000) / 1000);
    const msf = Math.floor((remainMillis % 1000) / 100);
    return `${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}:${msf}`;
  }, [remainMillis]);
  const render = () => (
    <div className="flex items-center gap-1">
      <DigitClock width={calcActualSize(icon)} height={calcActualSize(icon)} />
      <div
        className="text-[#FDE047] font-bold text-base"
        style={{fontSize: calcActualSize(size)}}>
        {remainTimeStr}
      </div>
    </div>
  );
  return {
    render,
    remainTimeStr,
  };
}
