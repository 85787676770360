import {http} from '@/utils';
import {
  TaskBonusListResult,
  UserGroupListResponse,
  TaskPageParams,
  TaskPageResult,
  ActivityDetailResponse,
  ActivityProgressInfoResponse,
} from './type';
import {packageId} from '@/config';

export function bonusUnComplete() {
  return http.post<null, number>('app/promotion/task/progress/bonus');
}

export function taskBonusPage(pageNum: number, pageSize: number) {
  return http.post<{pageNum: number; pageSize: number}, TaskBonusListResult>(
    'app/promotion/task/progress/progressing/list',
    {
      pageNum,
      pageSize,
    },
  );
}

// 用户当前任务记录数量(首页小红点)
export function getTaskCount() {
  return http.post<null, number>(
    'app/promotion/task/progress/completed/count',
    null,
  );
}

export interface GetRewardsResponse {
  /**
   * 跳转状态 true 跳转  false 不跳转
   */
  jumpStatus?: boolean;
  /**
   * 领取奖励状态 true 领取成功  false 领取失败
   */
  rewardStatus?: boolean;
  [property: string]: any;
}

// 领取奖励
export function getRewards(taskProgressId: string) {
  return http.post<null, GetRewardsResponse>('app/promotion/prize/issue', {
    taskProgressId,
  });
}

// 获取满足用户身份的活动列表
export function getUserGroupList() {
  return http.post<null, UserGroupListResponse[]>(
    'app/promotion/activity/rule/list',
  );
}

// 获取用户任务完成记录
export function getTaskCompletedList(params: TaskPageParams) {
  return http.post<TaskPageParams, TaskPageResult>(
    'app/promotion/task/progress/histories',
    params,
  );
}
export interface ActivityInfoParams {
  activityId: number | string;
  language: string;
}

// 获取活动详情
export function getActivityInfo(params: ActivityInfoParams) {
  return http.get<ActivityInfoParams, ActivityDetailResponse>(
    'app/promotion/activity/info',
    {
      params: {
        ...params,
        packageId: packageId,
      },
    },
  );
}

// 取消任务
export function cancelTask(taskProgressId: string) {
  return http.post<null, null>('app/promotion/task/progress/cancel', {
    taskProgressId,
  });
}

// 活动进度
export function getActivityProgress(activityId: number | string) {
  return http.get<null, ActivityProgressInfoResponse>(
    'app/promotion/activity/progress/info',
    {
      params: {
        activityId,
      },
    },
  );
}
