import theme from '@/style';
import React from 'react';
import {View, Animated, LayoutChangeEvent} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import SelectedSvg from '@/components/svgs/basic/selected';

export type QuickDigitPickerRef = {
  open: () => void;
  close: () => void;
};

interface QuickDigitPickerProps {
  list?: any[];
  activeIndex: number;
  onItemSelect: (index: number) => void;
}

const QuickDigitPicker = React.forwardRef<
  QuickDigitPickerRef,
  QuickDigitPickerProps
>(({list, activeIndex, onItemSelect}, ref) => {
  const drawerTranslateY = React.useRef(new Animated.Value(0)).current;
  const {screenWidth, screenHeight, calcActualSize} = useScreenSize();
  const disabled = React.useRef(true);
  const [visible, setVisible] = React.useState(false);
  const openAnimation = () => {
    disabled.current = true;
    Animated.timing(drawerTranslateY, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      disabled.current = false;
    });
  };
  const closeAnimation = () => {
    disabled.current = true;
    Animated.timing(drawerTranslateY, {
      toValue: -screenHeight,
      duration: 100,
      useNativeDriver: true,
    }).start(() => {
      disabled.current = false;
      setVisible(false);
    });
  };
  const laytouAnimatedView = (e: LayoutChangeEvent) => {
    Animated.timing(drawerTranslateY, {
      toValue: -e.nativeEvent.layout.height,
      duration: 0,
      useNativeDriver: false,
    });
  };

  const open = () => {
    openAnimation();
    setVisible(true);
  };

  const close = () => {
    closeAnimation();
  };

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }));
  return (
    <View
      style={[
        theme.fill.fill,
        theme.position.abs,
        theme.overflow.hidden,
        {top: 0, left: 0, zIndex: 99999},
        visible ? null : {display: 'none'},
      ]}>
      <NativeTouchableOpacity
        style={[
          theme.fill.fill,
          theme.position.abs,
          {top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)'},
        ]}
        activeOpacity={1}
        onPress={close}
      />
      <Animated.View
        onLayout={laytouAnimatedView}
        style={[
          {
            backgroundColor: '#FFFFFF',
            transform: [{translateY: drawerTranslateY || 0}],
            width: screenWidth,
            height: 'auto',
            maxHeight: '90%',
          },
        ]}>
        <View style={[theme.flex.col, {paddingVertical: calcActualSize(12)}]}>
          {/* <NativeTouchableOpacity style /> */}
          {list?.map((item, _index) => (
            <NativeTouchableOpacity
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
                {
                  height: calcActualSize(48),
                  paddingHorizontal: calcActualSize(12),
                  backgroundColor: _index % 2 === 0 ? '#F6F7FB' : undefined,
                },
              ]}
              key={item.pickThreeID}
              onPress={() => {
                onItemSelect(_index);
                close();
              }}>
              <Text calc size="medium" main>
                Quick 3D-{item.timeName}
              </Text>
              {_index === activeIndex && (
                <SelectedSvg
                  color={theme.basicColor.primary}
                  width={calcActualSize(12)}
                  height={calcActualSize(12)}
                />
              )}
            </NativeTouchableOpacity>
          ))}
        </View>
      </Animated.View>
    </View>
  );
});

export default QuickDigitPicker;
