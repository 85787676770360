import React from 'react';
import {useTranslation} from 'react-i18next';
import {goTo} from '@/utils';
import HomeLotteryImgTemplate from './lottery-img-template';
import {HomeGameRedGreenItem} from '../../home.type';

interface ColorProps {
  list: HomeGameRedGreenItem[];
  serverTime?: number;
  currentTime?: number;
}

const HomeLotteryColor = ({list = [], serverTime, currentTime}: ColorProps) => {
  const i18n = useTranslation();
  return (
    <HomeLotteryImgTemplate
      list={list}
      serverTime={serverTime}
      currentTime={currentTime}
      titleColor="rgba(232, 221, 181, 0.50)"
      title={i18n.t('home.color.title')}
      onlineUser={52420}
      onPress={item => {
        item &&
          // goTo('GameWebView', {
          //   type: 'color',
          //   // params: (item as HomeGameRedGreenItem).gameUrl,
          //   params: `cycle=${(item as HomeGameRedGreenItem)!.cycle}`,
          // });
          goTo('Color', {
            colorId: (item as HomeGameRedGreenItem)!.configId,
          });
      }}
      bgColor="#DB4F4F"
      odds={9}
    />
  );
};

export default React.memo(HomeLotteryColor);
