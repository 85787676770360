import {Modal, Image} from 'antd-mobile';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BonusType} from '../type';
import {toPriceStr} from '@/utils';
import BackgroundTimer from '@components/utils/timer';

const successIcon = require('@components/assets/icons/modal/success.webp');

interface ReceivedModalProps {
  visible: boolean;
  onClose: () => void;
  bonusType?: BonusType;
  bonus: number;
}

const ReceivedModal: React.FC<ReceivedModalProps> = ({
  visible,
  onClose,
  bonusType = 'ADD_BALANCE_AND_FROZEN',
  bonus,
}) => {
  const {t} = useTranslation();
  const icon = useMemo(() => {
    const bonusMap = {
      ADD_BALANCE_AND_FROZEN: require('@/assets/icons/promotions/coin.webp'),
      DIRECT_BALANCE: require('@/assets/icons/promotions/coin.webp'),
      FREE_TICKET: require('@/assets/icons/promotions/ticket.webp'),
      FREE_LOTTERY: require('@/assets/icons/promotions/spin.webp'),
    };
    return bonusMap[bonusType];
  }, [bonusType]);

  const [time, setTime] = useState(3);
  const timer = useRef<number>();
  const handleClose = useCallback(() => {
    if (timer.current) {
      BackgroundTimer.clearInterval(timer.current);
      timer.current = undefined;
    }
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      setTime(3);
      timer.current = BackgroundTimer.setInterval(() => {
        setTime(v => v - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        BackgroundTimer.clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [visible]);

  useEffect(() => {
    if (time <= 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const calcBonus = useMemo(() => {
    if (
      bonusType === 'ADD_BALANCE_AND_FROZEN' ||
      bonusType === 'DIRECT_BALANCE'
    ) {
      return toPriceStr(bonus, {fixed: 0});
    }
    return `x${bonus}`;
  }, [bonus, bonusType]);

  return (
    <Modal
      bodyClassName="bg-transparent"
      visible={visible}
      onClose={handleClose}
      showCloseButton={false}
      content={
        <div className="flex flex-col items-center justify-center gap-2 text-white bg-black/70 min-w-[18.5rem] p-6 rounded-xl">
          {/* 成功图标 */}
          <Image
            src={successIcon}
            fallback={<div className="w-full h-full bg-transparent" />}
            className="size-7"
          />

          {/* 标题 */}
          <div className="text-base font-bold">
            {t('promotionsV2.congratulations')}
          </div>

          {/* 奖励内容 */}
          <span className="text-sm capitalize">
            {t('promotionsV2.getBonus')}
          </span>

          {/* 金额 */}
          <div className="flex flex-row gap-1 items-center">
            <Image src={icon} className="size-6" />
            <span className="text-c2 text-[1.375rem] font-bold font-din">
              {calcBonus}
            </span>
          </div>
        </div>
      }
      closeOnMaskClick
    />
  );
};

export default ReceivedModal;
