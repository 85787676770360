import React from 'react';
import {View} from 'react-native';
import {PickGamesContent} from '../digit-service';
import TimeResult, {TimeResultProps} from './quick-time-result';

export interface QuickDigitProps {
  list?: PickGamesContent[];
  activeIndex?: number;
  showRule?: () => void;
  remainingTime?: number;
  onChangeTime?: (i: number) => void;
  activeInfo?: PickGamesContent;
  stopBetSec?: number;
}

const QuickDigit = ({
  activeInfo,
  showRule = () => {},
  stopBetSec,
  remainingTime = 0,
  ...props
}: QuickDigitProps & TimeResultProps) => {
  return (
    <View>
      <TimeResult
        drawResult={activeInfo?.lastResult?.drawResult || '***'}
        pickNum={activeInfo?.lastResult?.pickNo || '-'}
        pickName={'Quick 3D'}
        remainingTime={remainingTime}
        tip={activeInfo?.currentPickNo || '-'}
        showRule={showRule}
        pickGameType={'quick'}
        stopBetSec={stopBetSec}
        {...props}
      />
    </View>
  );
};

export default React.memo(QuickDigit);
