import React, {memo} from 'react';
import DigitResult from '../digit-tab/digit-result/result';
import OrderList from '../digit-tab/digit-order/order-list';
import InsideIm from '@/common-pages/im/insideIm';

const DigitTabView = ({
  refreshCode,
  pickThreeId,
  tabKey,
  type = 'normal',
}: {
  refreshCode: number;
  pickThreeId?: number;
  tabKey: string;
  type?: string;
}) => {
  const renderTabView = () => {
    switch (tabKey) {
      case 'result':
        return (
          <DigitResult
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={true}
          />
        );
      case 'order':
        return (
          <OrderList
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={true}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="h-full overflow-y-auto">
      {renderTabView()}
      <div
        className="h-full"
        style={{display: tabKey === 'chat' ? 'block' : 'none'}}>
        <InsideIm />
      </div>
    </div>
  );
};

export default memo(DigitTabView);
