import {Modal, Image, Button} from 'antd-mobile';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './modal.module.scss';
import {ReactComponent as ModalCloseIcon} from '@assets/icons/modal-close.svg';
// import {toPriceStr} from '@/utils';
const successIcon = require('@/assets/imgs/promotions/towithdraw.webp');
import BackgroundTimer from '@components/utils/timer';
import globalStore from '@/services/global.state';
import {toPriceStr} from '@/utils';

interface GoToWithdrawModalProps {
  visible: boolean;
  onClose: () => void;
  onClaimNow?: () => void;
  bonus: number;
}

const GoToWithdrawModal: React.FC<GoToWithdrawModalProps> = ({
  visible,
  onClose,
  onClaimNow,
  bonus,
}) => {
  const {t} = useTranslation();
  const timer = useRef<number>();
  const [time, setTime] = useState(10);

  const handleClose = useCallback(() => {
    if (timer.current) {
      BackgroundTimer.clearInterval(timer.current);
      timer.current = undefined;
    }
    onClose?.();
  }, [onClose]);

  const handleClaimNow = useCallback(() => {
    handleClose();
    onClaimNow?.();
  }, [onClaimNow, handleClose]);

  useEffect(() => {
    if (visible) {
      setTime(10);
      timer.current = BackgroundTimer.setInterval(() => {
        setTime(v => v - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        BackgroundTimer.clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [visible]);

  useEffect(() => {
    if (time <= 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Modal
      bodyClassName={styles['goto-withdraw-modal-body']}
      visible={visible}
      onClose={handleClose}
      showCloseButton={false}
      content={
        <div className="flex flex-col items-center justify-center gap-4 min-w-[22.4375rem] rounded-xl relative">
          {/* 成功图标 */}

          <div className="w-[22.4375rem] h-[23.8125rem] relative">
            <Image
              src={successIcon}
              fallback={<div className="w-full h-full bg-transparent" />}
              className="inset-0 absolute z-[1]"
            />
            <div className="w-[14.75rem] h-[7.5rem] flex flex-col items-center z-10 absolute top-[10.38375rem] left-[3.84375rem]">
              <div
                className={`text-[4rem] font-din font-black text-white flex flex-row items-end ${styles['amount-shadow']}`}>
                <div className="text-[2rem] pb-3">{globalStore.currency}</div>
                {toPriceStr(bonus, {fixed: 0, showCurrency: false})}
              </div>
              <span className="text-sm text-white capitalize">
                {t('promotionsV2.tip.withdraw')}
              </span>
            </div>

            <div
              className={`absolute bottom-[1.71875rem] left-[3.6rem] w-[14.75rem] h-12 flex justify-center items-center text-center text-t1 text-[1.25rem] font-bold capitalize z-[15] ${styles['claim-now-button']}`}
              onClick={handleClaimNow}>
              <span className="z-[15]">{t('promotionsV2.claimNow')}</span>
              <Image
                className="absolute inset-0"
                src={require('@/assets/imgs/promotions/claim-button-gold.webp')}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5 items-center">
            <Button className="size-8 bg-transparent p-0">
              <ModalCloseIcon className="size-8" />
            </Button>
            <span className="text-xs text-white">
              {t('tip.closeIn', {second: time})}
            </span>
          </div>
        </div>
      }
      closeOnMaskClick
    />
  );
};

export default GoToWithdrawModal;
