import LinearGradient from '@/components/basic/linear-gradient';
import theme from '@/style';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useTranslation} from 'react-i18next';
import QuickDigitNumbers from '@/common-pages/pick-digit/components/quick-digit-numbers';
import QuickDigitHeaderStatus from '@/common-pages/pick-digit/components/quick-digit-header-status';
import globalStore from '@services/global.state';
import {EventType} from '@/common-pages/im/useChatMessage';
const BASE_NUM = 147;
export interface TimeResultProps {
  showRule?: () => void;
  pickName?: string;
  pickNum?: string;
  drawResult?: string;
  tip?: string;
  remainingTime?: number;
  pickGameType?: string;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSameRoute?: boolean;
  stopBetSec?: number;
  timeVersion?: number;
  handleTimeChange: (min: number) => void;
}

const TimeResult = ({
  showRule,
  drawResult,
  tip,
  remainingTime,
  // pickGameType = 'normal',
  onTimeEnd,
  handleTimeChange,
  // onDisabled,
  // isSameRoute = true,
  timeVersion = 0,
  stopBetSec,
}: TimeResultProps) => {
  const {i18n} = useTranslation();
  const [memberCount, setMemberCount] = useState(BASE_NUM);
  const [rolling, setRolling] = useState(false);
  const [countDownTime, setCountDownTime] = useState(4);
  React.useEffect(() => {
    if (remainingTime === 0) {
      onTimeEnd && onTimeEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime, timeVersion]);
  useEffect(() => {
    setRolling(true);
    const timer = setTimeout(
      () => {
        setRolling(false);
      },
      timeVersion ? 1000 : 1000 * 2,
    );
    return () => {
      timer && clearTimeout(timer);
    };
  }, [timeVersion]);
  const handleLocalTime = (min: number) => {
    setCountDownTime(Math.floor(min / 1000));
    handleTimeChange(min);
  };
  useEffect(() => {
    const chatMessageSub = globalStore.onImMessage.subscribe(data => {
      if (data.type === EventType.ROOM_MEMBER_COUNT) {
        setMemberCount(data.payload.memberNum + BASE_NUM);
      }
    });
    return () => {
      chatMessageSub.unsubscribe();
    };
  }, []);

  return (
    <LinearGradient colors={['#AFBFC0', '#AFBFC0']}>
      <View
        style={[
          theme.flex.row,
          theme.padding.lrl,
          theme.padding.tbs,
          theme.flex.centerByCol,
        ]}>
        <div>
          <span className="text-xs font-medium text-t1 flex gap-1">
            <span className="font-bold">{memberCount}</span>
            Player Betting
          </span>
        </div>
        <div className="ml-auto">
          <div
            className="rounded bg-white/70 w-[5.0625rem] h-6 flex justify-center items-center active:brightness-75"
            onClick={() => showRule?.()}>
            <span className="font-inter font-medium text-t2 text-xs">
              {i18n.t('game-page.label.howToPlay')}
            </span>
          </div>
        </div>
      </View>
      <QuickDigitNumbers
        nums={drawResult || ''}
        rolling={countDownTime <= (stopBetSec || 30) ? true : rolling}
      />
      <div className="p-1">
        <QuickDigitHeaderStatus
          issueNo={tip}
          timeVersion={timeVersion}
          minBetSeconds={stopBetSec}
          remainingTime={remainingTime}
          onTimeEnd={onTimeEnd}
          onTimeChange={handleLocalTime}
        />
      </div>
      {countDownTime <= 3 && (
        <div className="absolute h-full w-full left-0 top-0 bg-black/70 bottom-0 right-0 flex justify-center items-center">
          <span className="font-din text-white font-bold text-7xl">
            {countDownTime}
          </span>
        </div>
      )}
    </LinearGradient>
  );
};

export default React.memo(TimeResult);
