import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack} from '@/utils';
import React from 'react';
import TriangleIcon from '@/components/svgs/basic/triangle';

export interface NavHeaderProps {
  selectName?: string;
  onChange?: () => void;
  disabled?: boolean;
}

const QuickHeader = ({selectName = '', onChange}: NavHeaderProps) => {
  return (
    <DetailNavTitle
      hideServer={true}
      leftNode={
        <div onClick={() => onChange?.()} className="flex items-center">
          <div className="text-t1 font-bold font-inter max-w-[11.25rem]">
            {selectName}
          </div>
          <div className="ml-1">
            <TriangleIcon />
          </div>
        </div>
      }
      onBack={() => goBack()}
      hideTitle
    />
  );
};

export default QuickHeader;
