import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as QuestionIcon} from '@/assets/icons/promotions/question.svg';
import {Button, Image, ProgressBar} from 'antd-mobile';
import styles from './bonus-item.module.scss';
import {toPriceStr} from '@/utils';
import {Trans, useTranslation} from 'react-i18next';
import BackgroundTimer from '@components/utils/timer';
import {BonusType, StatusType} from './type';
import {useConfirm} from '@/components/basic/modal';

interface BonusItemProps {
  title?: string;
  tip?: string;
  currentAmount?: number;
  totalAmount?: number;
  remain?: [number, number, number, number];
  bonusType?: BonusType;
  bonus?: number;
  multiple?: number;
  status?: StatusType;
  received?: boolean;
  onClimb?: () => void;
  onDelete?: () => void;
}

export const CountDown = ({
  remain,
  onEnd,
}: {
  remain: [number, number, number, number];
  onEnd?: () => void;
}) => {
  const timer = useRef<number>();
  const [timeVersion, setTimeVersion] = useState(0);
  const timeEnd = useMemo(() => {
    const [d, h, m, s] = remain;
    return (
      Date.now() +
      d * 24 * 60 * 60 * 1000 +
      h * 60 * 60 * 1000 +
      m * 60 * 1000 +
      s * 1000
    );
  }, [remain]);

  const timeRemain = useMemo(() => {
    const _remain = timeEnd - Date.now();
    if (_remain < 0) {
      return [0, 0, 0, 0];
    }
    let s = Math.ceil(_remain / 1000);
    let m = Math.floor(s / 60);
    s = s % 60;
    let h = Math.floor(m / 60);
    m = m % 60;
    let d = Math.floor(h / 24);
    h = h % 24;
    return [d, h, m, s];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEnd, timeVersion]);

  const [days, hours, minutes, seconds] = timeRemain;

  useEffect(() => {
    timer.current = BackgroundTimer.setInterval(() => {
      setTimeVersion(v => v + 1);
    }, 1000);
    return () => {
      timer.current && BackgroundTimer.clearInterval(timer.current);
    };
  }, [remain]);

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      if (timer.current) {
        BackgroundTimer.clearInterval(timer.current);
      }
      onEnd?.();
    }
  }, [days, hours, minutes, seconds, onEnd]);

  return (
    <div className="flex flex-row items-center text-t2 text-xs font-bold gap-2">
      {days > 0 && <span>{days} Day</span>}
      <div className="flex flex-row items-center gap-0.5">
        <span
          className={`size-5 flex items-center justify-center text-center bg-[#E7ECF6] rounded-sm ${styles['countdown-item']}`}>
          {hours.toString().padStart(2, '0')}
        </span>
        <span>:</span>
        <span
          className={`size-5 flex items-center justify-center text-center bg-[#E7ECF6] rounded-sm ${styles['countdown-item']}`}>
          {minutes.toString().padStart(2, '0')}
        </span>
        <span>:</span>
        <span
          className={`size-5 flex items-center justify-center text-center bg-[#E7ECF6] rounded-sm ${styles['countdown-item']}`}>
          {seconds.toString().padStart(2, '0')}
        </span>
      </div>
    </div>
  );
};

const BonusItem = ({
  title = '-',
  tip = '',
  currentAmount = 0,
  totalAmount = 1,
  remain = [0, 0, 0, 0],
  bonusType = 'ADD_BALANCE_AND_FROZEN',
  bonus = 0,
  multiple = 1,
  received = false,
  onClimb = () => {},
  onDelete = () => {},
  status = 'PROGRESSING',
}: BonusItemProps) => {
  const {t} = useTranslation();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();

  const icon = useMemo(() => {
    const bonusMap = {
      ADD_BALANCE_AND_FROZEN: require('@/assets/icons/promotions/coin.webp'),
      DIRECT_BALANCE: require('@/assets/icons/promotions/coin.webp'),
      FREE_TICKET: require('@/assets/icons/promotions/ticket.webp'),
      FREE_LOTTERY: require('@/assets/icons/promotions/spin.webp'),
    };
    return bonusMap[bonusType];
  }, [bonusType]);

  const calcBonus = useMemo(() => {
    if (
      bonusType === 'ADD_BALANCE_AND_FROZEN' ||
      bonusType === 'DIRECT_BALANCE'
    ) {
      return toPriceStr(bonus);
    }
    return `x${bonus}`;
  }, [bonus, bonusType]);

  const calcTotalAmount = useMemo(() => {
    return Math.max(totalAmount, 1);
  }, [totalAmount]);

  const calcCurrentAmount = useMemo(() => {
    return Math.min(currentAmount, calcTotalAmount);
  }, [currentAmount, calcTotalAmount]);

  const progress = useMemo(() => {
    return (calcCurrentAmount / calcTotalAmount) * 100;
  }, [calcCurrentAmount, calcTotalAmount]);

  const [isEnd, setIsEnd] = useState(false);

  const isExpired = useMemo(() => {
    return status === 'EXPIRED' || isEnd;
  }, [status, isEnd]);

  const buttonText = useMemo(() => {
    if (status === 'COMPLETED') {
      if (received) {
        return t('promotionsV2.received');
      }
      return t('promotionsV2.receive');
    }
    if (isExpired) {
      return t('promotionsV2.expired');
    }
    return t('promotionsV2.climb');
  }, [status, isExpired, t, received]);

  const handleEnd = () => {
    setIsEnd(true);
  };

  return (
    <div
      className={`flex flex-col bg-white p-2 gap-3 ${styles['bonus-item']} rounded-lg`}>
      <div
        className={`flex flex-col p-2 gap-4 bg-[#F6F6F6] ${
          isExpired ? 'opacity-50' : ''
        }`}>
        <div className="h-[1.375rem] flex flex-row gap-2.5 justify-between items-center">
          <span className="text-t1 text-xl font-bold capitalize flex-1 text-nowrap text-ellipsis overflow-hidden break-all">
            {title}
          </span>
          {!!tip && (
            <Button className="p-0 bg-transparent">
              <QuestionIcon className="size-4 text-t3" />
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <ProgressBar percent={progress} />
          <span className="text-t2 text-xs font-bold capitalize">
            <span className="text-c2">{toPriceStr(calcCurrentAmount)} </span>/{' '}
            {toPriceStr(calcTotalAmount)}
          </span>
        </div>
        <div className="flex flex-row gap-3 items-center justify-between">
          <div className="flex flex-col flex-1 overflow-hidden">
            <div className="text-t2 text-xs capitalize font-normal text-nowrap text-ellipsis overflow-hidden break-all">
              <Trans
                i18nKey="promotionsV2.tip.claim"
                components={[<span className="text-c2 font-normal" />]}
                values={{
                  amount: toPriceStr(calcTotalAmount - calcCurrentAmount, {
                    suffixUnit: 'K',
                    round: 'ceil',
                  }),
                }}
              />
            </div>
            <div className="text-t2 text-xs capitalize font-normal text-nowrap text-ellipsis overflow-hidden break-all">
              <Trans
                i18nKey="promotionsV2.tip.required"
                components={[<span className="text-c2 font-normal" />]}
                values={{
                  multiple: `${multiple}X`,
                }}
              />
            </div>
          </div>

          {status === 'COMPLETED' && received ? (
            <></>
          ) : (
            <span className="text-t2 text-xs font-bold capitalize">
              <CountDown remain={remain} onEnd={handleEnd} />
            </span>
          )}
        </div>
      </div>
      <div
        className={`flex flex-row justify-between items-center pb-1 px-1 gap-3 ${
          isExpired ? 'opacity-50' : ''
        }`}>
        <div className="flex flex-row gap-1 items-center">
          <Image src={icon} className="size-6" />
          <span className="text-c2 text-[1.375rem] font-black font-din">
            {calcBonus}
          </span>
        </div>
        <div className="flex flex-row gap-2">
          {status !== 'COMPLETED' && !isExpired && (
            <Button
              className={
                'w-[4.625rem] text-sm font-bold h-[2.0625rem] flex items-center justify-center rounded-full border border-t3 text-t2 bg-transparent before:rounded-full'
              }
              onClick={() => {
                confirmShow(
                  t('label.delete'),
                  t('promotionsV2.tip.delete'),
                  () => {
                    onDelete?.();
                  },
                );
              }}>
              {t('label.delete')}
            </Button>
          )}
          <Button
            className={`${
              (status === 'COMPLETED' && received) || isExpired
                ? 'bg-image text-t3'
                : 'bg-c1 text-white'
            } ${
              status === 'COMPLETED' || isExpired
                ? 'w-[7.25rem]'
                : 'w-[4.625rem]'
            } text-sm font-bold !opacity-100 h-[2.0625rem] flex items-center justify-center rounded-full before:rounded-full ${
              styles['bonus-item-button']
            }`}
            disabled={status === 'COMPLETED' && received}
            onClick={() => onClimb?.()}>
            {buttonText}
          </Button>
        </div>
      </div>
      {renderConfirmModal}
    </div>
  );
};

export default BonusItem;
