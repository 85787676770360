import React, {useEffect, useState} from 'react';
import {FaceSvg} from '@/components/svgs/basic';
import globalStore from '@services/global.state';
// @ts-ignore
type ChatBottomProps = {
  disabled: boolean;
  handlePlay: () => void;
  handleChat: (visible: boolean) => void;
};
export default function ChatBottom(props: ChatBottomProps) {
  const {handleChat} = props;
  const [token, setToken] = useState(localStorage.getItem('token'));
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setToken(token || '');
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!token) return null;
  return (
    <div className="h-14 py-1 px-3 bg-white flex-shrink-0 w-full flex gap-3 items-center">
      <div
        className="flex-1 bg-[#f5f5f5] rounded-full text-t2 font-medium flex gap-1 items-center px-2 h-[2.75rem]"
        onClick={() => handleChat(true)}>
        <FaceSvg width={'1.5rem' as any} height={'1.5rem' as any} />
        <div className="text-base text-t2 font-medium">Comment...</div>
      </div>
      <div
        onClick={props.handlePlay}
        className="w-[7.5rem] h-full active:brightness-90 bg-[#019A45] rounded-xl text-base text-white font-bold flex justify-center items-center"
        style={{
          opacity: props.disabled ? 0.4 : 1,
          pointerEvents: props.disabled ? 'none' : 'inherit',
        }}>
        play
      </div>
    </div>
  );
}
