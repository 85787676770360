import globalStore from '@services/global.state';
import {getUUID} from '@components/utils';
import {packageId} from '@/config';

export function sendImAdd3DLocalMessage(
  issueNo: string,
  amount: number,
  betsList: any[],
) {
  // 这里进行im的交互，用户im端手动发送消息，系统推送无法推送自身
  const imMessage = betsList.map(item => {
    return {
      type: 200,
      subType: 5,
      timestamp: Date.now(),
      messageID: getUUID(),
      conversationID: '',
      conversationType: 1,
      sentStatus: 1,
      direction: 1,
      receiptStatus: 2,
      message: {
        gameType: 'quick3d',
        groupId: '',
        packageId: packageId,
        issueNo: issueNo,
        content: {
          amount: item.basePrice * item.count,
          pickInfoId: item.pickInfoId,
          index: item.type,
          number: item.value,
          count: item.count,
          level: item.type.length,
        },
        subMsgType: 5,
        imUserId: '',
        chatType: '1',
      },
    };
  });
  imMessage.forEach(message => {
    globalStore.chatInsertMessage.next({
      message,
    });
  });
}
