import {useState} from 'react';
import {goBack, goTo} from '@utils';
import {BasicObject} from '@components/types';
import globalStore from '@services/global.state';

// const gameMap: {
//   [key: string]: string;
// } = {
//   color: 'Color',
//   dice: 'Dice',
//   '3Digit': 'Digit',
//   quick3d: 'Digit',
//   scratch: 'Scratch',
//   matka: 'GameSatta',
//   kerala: 'GameWebView',
//   quickStateLottery: 'MixLottery',
//   stateLottery: 'MixLottery',
// };

export enum EventType {
  PLAY = 'play',
  FOLLOWED = 'followed',
  LOGIN = 'login',
  SUCCESS = 'success',
  FAIL = 'fail',
  BACK = 'back',
  USER_EDITOR = 'userEditor',
  START_GAME = 'startGame',
  ROUTE_CHANGED = 'routeChange',
  TABBA_CHANGED = 'tabBar',
  FOCUS_CHANGED = 'focusChanged',
  UNREAD_CHANGED = 'unreadChanged',
  INPUT_FOCUS = 'input_focus', // 主要修复im ios 键盘问题
  // native路由变化
  NATIVE_ROUTE_CHANGE = 'nativeRouteChange',
  FORECAST = 'forecast', // 彩票预测
  Message = 'message',
  IM_LOCAL_MESSAGE = 'imLocalMessage',
  ROOM_MEMBER_COUNT = 'roomMemberCount',
}

interface IDataFromChat {
  source: string | 'chat-room';
  payload: any;
}
export function useChatMessage() {
  const [pageLoading, setPageLoading] = useState(true);
  const handleMessage = (data: string | BasicObject | IDataFromChat) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (typeof data === 'object' && data.source === 'chat-room') {
      localStorage.setItem('from', 'im');
      const {event, data: fromData} = data.payload || {};
      const {userInviteCode} = fromData || {};
      if (userInviteCode) {
        localStorage.setItem('invitationCode', userInviteCode);
      }
      if (fromData.shareId) {
        localStorage.setItem('shareUserId', fromData.shareId);
      }
      switch (event) {
        case EventType.BACK: {
          goBack();
          break;
        }
        case EventType.LOGIN:
          // const curLink = window.location.href.split('?')[0];
          // const jumpLink = curLink.replace('index/chat', 'login?fromPage=chat');
          // window.location.replace(jumpLink);
          goTo('Login', {fromPage: 'home'});
          break;
        case EventType.PLAY: {
          // playNow(fromData);
          break;
        }
        case EventType.FOLLOWED: {
          break;
        }
        case EventType.FORECAST: {
          break;
        }
        case EventType.USER_EDITOR: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('index/chat', 'profile');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.START_GAME:
          break;
        case EventType.ROUTE_CHANGED:
          break;
        case EventType.TABBA_CHANGED:
          break;
        case EventType.UNREAD_CHANGED:
          break;
        case EventType.INPUT_FOCUS:
          if (fromData.isFocused) {
            document.documentElement.scrollIntoView();
          }
          break;
        case EventType.ROOM_MEMBER_COUNT:
          const memberNum = data.payload.data.count;
          globalStore.onImMessage.next({
            type: EventType.ROOM_MEMBER_COUNT,
            payload: {
              memberNum,
            },
          });
          break;
      }
    }
  };

  return {
    onMessage: handleMessage,
    pageLoading,
    setPageLoading,
  };
}
