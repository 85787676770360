import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import LinearGradient from '@/components/basic/linear-gradient';
import {StyleSheet} from 'react-native';
import {useTranslation} from 'react-i18next';
import Shadow from '@basicComponents/shadow';
const QuickBtn = ({onPress}: {onPress?: () => void}) => {
  const {
    i18n: {t},
  } = useTranslation();
  return (
    <NativeTouchableOpacity onPress={() => onPress && onPress()}>
      <Shadow
        shadowStyle={{
          radius: 4,
          out: {x: 0, y: 2, blur: 4, color: 'rgba(0, 0, 0, 0.25)'},
        }}>
        <LinearGradient
          style={[
            theme.padding.tbs,
            theme.padding.lrl,
            theme.borderRadius.xs,
            styles.btn,
          ]}
          colors={['#FCFCFE', '#F3F5FB']}>
          <Text fontFamily="fontInterBold">
            {t('game-page.label.quickGuess')}
          </Text>
        </LinearGradient>
      </Shadow>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  btn: {
    elevation: 6,
  },
});

export default QuickBtn;
