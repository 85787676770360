import globalStore from '@/services/global.state';
import {goTo} from './route';
import {inApp} from './interface';
import {getAdjustAttribution} from './adjust';
import Clipboard from '@react-native-clipboard/clipboard';

export function needLoginBeforeDownload() {
  return (
    !globalStore.token &&
    (new URL(location.href).searchParams.get('pixelid') != null ||
      localStorage.getItem('pixelid') != null)
  );
}

export function stopBeforeDownload() {
  if (needLoginBeforeDownload()) {
    goTo('Login', {backPage: 'Home'});
    return true;
  }
  return false;
}

function getRegisterChannel(userInviteCode?: string) {
  const attr = getAdjustAttribution();
  const trackerName = attr && attr.tracker_name;
  return trackerName && trackerName !== 'Organic'
    ? 'Promotion' /*推广*/
    : userInviteCode // 只有落地页还有邀请码
    ? 'AppShare'
    : 'Organic';
}

function getDeviceInfo() {
  const ua = navigator.userAgent;
  const info = {
    deviceBrand: 'Unknown',
    deviceModel: 'Unknown',
  };
  try {
    if (inApp) {
      const matchStr = ua.split('Mobile')?.[1]?.split(';')?.[1];
      const [deviceBrand, deviceModel] =
        matchStr?.split(';')?.[0]?.split('/') || [];
      info.deviceBrand = deviceBrand || 'Unknown';
      info.deviceModel = deviceModel || 'Unknown';
    } else {
      if (ua.indexOf('iPhone') > -1) {
        console.log('iPhone');
        info.deviceBrand = 'iPhone';
        info.deviceModel =
          ua.split('Mobile/')?.[1]?.split(' ')?.[0] || 'Unknown';
      } else if (ua.indexOf('Android') > -1) {
        info.deviceBrand = 'Android';
        info.deviceModel =
          ua.split('Mobile')?.[1]?.split(' ')?.[0]?.split('/')?.[1] ||
          'Unknown';
      }
    }
  } catch (e) {
    console.log('Error:', e);
  }
  return info;
}

export function getAttribution(userInviteCode?: string) {
  return {
    registerChannel: getRegisterChannel(userInviteCode),
    platform: inApp ? 'app' : 'h5',
    ...getDeviceInfo(),
  };
}

export function copyText(text: string) {
  if (inApp) {
    try {
      (window as any).Android?.copyToClipboard(text);
    } catch (e) {
      Clipboard.setString(text);
    }
  } else {
    Clipboard.setString(text);
  }
}
/**
 * @description 返回ab版本
 * */
export function getUserVersion() {
  const getUniqueIdentifier = () => {
    const key = 'APP_UNIQUE_UUID';
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key)!;
    } else {
      const uuid = Math.random().toString(36).substring(2);
      localStorage.setItem(key, uuid);
      return uuid;
    }
  };
  const uniqueId = getUniqueIdentifier();
  let sum = 0;
  for (let i = 0; i < uniqueId.length; i++) {
    sum += uniqueId.charCodeAt(i);
  }
  return sum % 2 === 0;
}

const gameRates = {
  DICE: [90.45, 93.21, 95.67, 91.83, 94.32, 92.76, 95.11, 90.89, 93.54, 94.98],
  COLOR: [90.7, 94.08, 93.39, 92.09, 95.71, 91.3, 94.95, 90.1, 95.46, 93.5],
  Quick: [90.77, 94.61, 93.34, 91.25, 95.03, 92.37, 94.63, 91.39, 93.17, 95.04],
  Racecar: [
    90.29, 92.75, 93.83, 91.96, 94.9, 92.14, 95.77, 91.65, 93.11, 94.88,
  ],
};

const numberRates = {
  QUICK: [
    150234, 111456, 132567, 143789, 124890, 105901, 133123, 112987, 121876,
    110765,
  ],
  MIX: [51113, 50287, 53357, 52498, 52555, 54669, 53787, 52832, 51913, 50489],
  KERALA: [
    51876, 53399, 54763, 51349, 50780, 50640, 50674, 51922, 50209, 50407,
  ],
  MATKA: [52639, 53907, 50109, 51789, 52532, 53611, 54628, 55732, 52599, 53699],
  OTHERS: [
    125739, 114507, 133309, 122989, 141132, 108691, 130678, 111739, 133699,
    151879,
  ],
};

const getRate = (gameType: string, listType: any) => {
  // 获取当前时间戳（毫秒）
  const timestamp = Date.now();
  // 将时间戳转换为小时数（向下取整）
  const hours = Math.floor(timestamp / (1000 * 60 * 60));
  // 每2小时一个周期
  const periodIndex = Math.floor(hours / (5 / 6));

  // 获取对应游戏类型的胜率数组
  const rates = listType[gameType];
  if (!rates) {
    return null; // 如果游戏类型不存在，返回null
  }

  // 使用周期索引对数组长度取模，确保循环使用数组中的所有数据
  const index = periodIndex % rates.length;
  return rates[index];
};

export const getGameRates = (gameType: string) => getRate(gameType, gameRates);
export const getNumberRates = (gameType: string) =>
  getRate(gameType, numberRates);
