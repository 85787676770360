import axios, {AxiosResponse} from 'axios';
import globalStore from '@services/global.state';
import {envConfig, goTo, parseCookie, VERSION_CODE} from '@utils';
import {BasicObject} from '@/types';
import {defaultChannel, homePage, packageId, packageInfo} from '@/config';
import AsyncStorage from '@react-native-async-storage/async-storage';

const header = {
  'Content-Type': 'application/json',
  packageId: packageId + '',
};
interface CreateHttpParams {
  baseUrl: string;
  mergeHeader?: BasicObject;
  mergeData?: BasicObject;
}
export const datas: any = {
  packageId: packageId,
  packageInfo: packageInfo,
  versionCode: VERSION_CODE,
};
const getToken = async () => {
  const token = await AsyncStorage.getItem('token');
  return token;
};
const SERVER_BUSY_CODE = 1;
const rejectResponse = <T>(
  response: AxiosResponse<
    {
      data: T;
      code: number;
      msg: string;
    },
    any
  >,
) => {
  if (response.data.code !== SERVER_BUSY_CODE) {
    globalStore.globalTotal.next({
      type: 'warning',
      message: response.data.msg,
    });
  }
  return Promise.reject(response);
};
const createHTTP = ({
  baseUrl,
  mergeHeader = {},
  mergeData = {},
}: CreateHttpParams) => {
  // 创建一个Axios实例
  const http = axios.create({
    baseURL: baseUrl,
    timeout: 60000, // 设置请求超时时间
    headers: {
      ...header,
      ...mergeHeader,
    },
  });
  // 请求拦截器
  http.interceptors.request.use(
    async config => {
      config.data = {
        channel: globalStore.isAndroid ? 'Android' : 'h5',
        channelId: globalStore.channel || defaultChannel,
        lang: globalStore.lang,
        visitor: globalStore.visitor,
        reqDate: new Date().getTime(),
        ua: navigator.userAgent,
        ...datas,
        ...mergeData,
        ...config.data,
      };
      const token = globalStore.token || (await getToken());
      if (token) {
        config.headers.Token = token;
      }
      config.headers.visitor = globalStore.visitor;
      if (globalStore.isWeb) {
        const cookiedata = parseCookie();
        config.headers.pixelid = localStorage.getItem('pixelid');
        config.headers.fbp = cookiedata._fbp;
        config.headers.fbc = cookiedata._fbc;
      }
      return config;
    },
    (error: any) => {
      globalStore.globalTotal.next({
        type: 'warning',
        message: error.message,
      });
      return Promise.reject(error);
    },
  );

  // 响应拦截器
  http.interceptors.response.use(
    <T>(
      response: AxiosResponse<{
        data: T;
        code: number;
        msg: string;
        status?: number;
      }>,
    ) => {
      if (response.status === 200) {
        if (
          response.data.code === -1
          // TODO 授权失败返回-1
          // 授权失败导致的登录一律返回首页
          // 应该尽可能避免被接口触发,而应该通过前端逻辑或者路由守卫
          // response.data.msg === 'Authorization failed!'
        ) {
          globalStore.token = null;
          globalStore.userInfo = null;
          goTo('Login', {
            backPage: homePage,
          });
        } else if (response.data.code === 0 || response.data.status === 0) {
          return response.data.data;
        } else if (response.data.code === 14) {
          // TODO 这里专门为代理接口处理异常,应该使用新的接口后删除这段逻辑
          return response.data.data;
        } else if (response.data.code === 5509) {
          goTo('Recharge');
        } else {
          return rejectResponse(response);
        }
      }
      return rejectResponse(response);
    },
    error => {
      globalStore.globalTotal.next({
        type: 'warning',
        message: error.message,
      });
      return Promise.reject(error);
    },
  );

  return http;
};
export const trackHttp = createHTTP({
  baseUrl: envConfig.induswinUrl || envConfig.baseUrl,
});
