import './quick-digit-number-item.css';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
const numImage = require('@/assets/icons/slots_numbers.webp');
const drawSlotImage = require('@/assets/icons/draw_slot.webp');
interface QuickDigitNumberItemProps {
  num: number;
  rolling?: boolean;
  itemHeight?: number;
  delay?: number;
}

const QuickDigitNumberItem = ({
  num,
  itemHeight,
  rolling,
  delay = 0.2,
}: QuickDigitNumberItemProps) => {
  const numberStyle = useCallback((_num: number) => {
    return {
      backgroundImage: `url(${numImage})`,
      backgroundPosition: `-${1.875 * _num}rem 0`,
    };
  }, []);

  const containerVarStyle = useMemo(() => {
    return {
      '--i': (num + 1) % 10,
      '--delay': delay,
    } as Record<string, any>;
  }, [num, delay]);

  const hasAnimateScroll = useMemo(() => {
    return rolling;
  }, [rolling]);

  const [hasAnimateBounce, setHasAnimateBounce] = useState(false);
  const first = useRef(true);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (!rolling && !first.current) {
      setHasAnimateBounce(true);
      timer = setTimeout(() => {
        setHasAnimateBounce(false);
      }, delay * 1000 + 2400);
    }
    if (first.current) {
      first.current = false;
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [rolling, delay]);

  return (
    <div
      className="flex flex-col w-full h-full items-center justify-center bg-cover"
      style={{height: itemHeight, backgroundImage: `url(${drawSlotImage})`}}>
      <div
        className="w-[1.875rem] h-[2.75rem] overflow-hidden absolute"
        data-v-03806bc5=""
        data-v-992831cf=""
        style={containerVarStyle}>
        <div
          data-v-03806bc5=""
          className={`list_box ${hasAnimateScroll ? 'animate-scroll' : ''} ${
            hasAnimateBounce ? 'animate-bounce' : ''
          }`}>
          {Array.from({length: 12}).map((_, index) => (
            <div
              data-v-03806bc5=""
              key={index}
              className="w-[1.875rem] h-[2.75rem] bg-[length:18.75rem_2.75rem] bg-no-repeat"
              style={numberStyle((index + 9) % 10)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickDigitNumberItem;
