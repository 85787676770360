import React, {useMemo} from 'react';
import {BonusType} from '../type';
import {useTranslation} from 'react-i18next';
import {toPriceStr} from '@/utils';

export interface BonusItemProps {
  title: string;
  bonusType: BonusType;
  bonus?: number;
  claimTime: string;
  status: string;
}

const BonusItem: React.FC<BonusItemProps> = ({
  title,
  bonusType,
  bonus,
  claimTime,
  status,
}) => {
  const {t} = useTranslation();
  const bonusTitle = useMemo(() => {
    if (
      bonusType === 'ADD_BALANCE_AND_FROZEN' ||
      bonusType === 'DIRECT_BALANCE'
    ) {
      return t('promotionsV2.bonus');
    }
    return t('promotionsV2.reward');
  }, [bonusType, t]);
  const bonusResult = useMemo(() => {
    if (
      bonusType === 'ADD_BALANCE_AND_FROZEN' ||
      bonusType === 'DIRECT_BALANCE'
    ) {
      return `+ ${toPriceStr(bonus)}`;
    }
    return `${t(`promotionsV2.prize.${bonusType}`)} x${bonus}`;
  }, [bonusType, bonus, t]);
  return (
    <div
      className={`bg-white py-4 px-3 rounded-lg shadow flex flex-col gap-4 ${
        status === 'EXPIRED' ? 'opacity-50' : ''
      }`}>
      <div className="flex flex-row justify-between gap-3">
        <h3 className="text-t1 text-sm font-bold capitalize flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all">
          {title}
        </h3>
        <div className="flex flex-col items-end">
          <span className="text-t3 text-xs">{bonusTitle}</span>
          <span className="text-t1 text-base font-bold font-din">
            {bonusResult}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <span className="text-t3 text-xs">{t('promotionsV2.claimTime')}</span>
        <span className="text-t2 text-sm">{claimTime}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span className="text-t3 text-xs">{t('promotionsV2.status')}</span>
        <span className="text-t2 text-sm">{status}</span>
      </div>
    </div>
  );
};

export default BonusItem;
