import React, {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Badge, Image} from 'antd-mobile';
import styles from './me.module.scss';
import {ReactComponent as PromotionRight} from '@/assets/icons/me/promotion-right.svg';
import {useLogin} from '@/utils/state-hooks';
import {useFocusEffect} from '@react-navigation/native';
import {getTaskCount} from '../promotions/service';
import {goTo} from '@/utils';
const MePromotions = () => {
  const {t} = useTranslation();
  const [taskCount, setTaskCount] = useState(0);
  const login = useLogin();

  useFocusEffect(
    useCallback(() => {
      if (!login) {
        return;
      }
      getTaskCount().then(data => {
        setTaskCount(data);
      });
    }, [login]),
  );
  return (
    <div
      className={`flex flex-row gap-2 p-3 ${styles['me-promotions-linear']}`}>
      {/* 促销卡片 */}
      <div
        className={`flex-1 flex flex-col rounded-lg p-2.5 overflow-hidden border border-solid gap-2 border-black/10 ${styles['me-promotions-promotion-linear']}`}
        onClick={() => {
          goTo('PromotionsV2', {tabKey: 'promotion'});
        }}>
        <div className="flex items-center gap-2">
          <Image
            src={require('@/assets/icons/me/me-promotions.webp')}
            className="w-10 h-10"
            alt="promotions"
            fit="fill"
          />
          <div className="flex flex-col flex-1">
            <span className="text-base font-bold text-t1">
              {t('promotionsV2.promotion')}
            </span>
            <div className="flex flex-row items-center gap-1">
              <Image
                src={require('@/assets/icons/me/me-promotions-win.webp')}
                className="w-[5.21875rem] h-[1.0625rem]"
                alt="promotions"
                fit="fill"
              />
              <PromotionRight />
            </div>
          </div>
        </div>

        <div className="text-t3 text-xs tracking-[-.018rem] capitalize">
          {t('me.tip.dailyRewards')}
        </div>
      </div>

      {/* 奖金卡片 */}
      <div
        className={`flex-1 flex flex-col rounded-lg p-2.5 overflow-hidden border border-solid gap-2 border-black/10 ${styles['me-promotions-bonus-linear']}`}
        onClick={() => {
          if (!login) {
            goTo('Login');
            return;
          }
          goTo('PromotionsV2', {tabKey: 'bonus'});
        }}>
        <div className="flex items-center gap-2">
          <Badge content={taskCount > 0 ? Badge.dot : null} color="#F00">
            <Image
              src={require('@/assets/icons/me/me-bonus.png')}
              className="w-10 h-10"
              alt="promotions"
              fit="fill"
            />
          </Badge>
          <div className="flex flex-col flex-1">
            <span className="text-base font-bold text-t1">
              {t('promotionsV2.bonus')}
            </span>
            <div className="flex flex-row items-center gap-1">
              <Image
                src={require('@/assets/icons/me/me-promotions-bonus.webp')}
                className="w-[5.21875rem] h-[1.0625rem]"
                alt="promotions"
                fit="fill"
              />
              <PromotionRight />
            </div>
          </div>
        </div>

        <div className="text-t3 text-xs tracking-[-.022rem] capitalize">
          <Trans
            i18nKey="me.tip.bonus"
            components={[<span className="text-[#F15802] font-bold" />]}
          />
        </div>
      </div>
    </div>
  );
};

export default MePromotions;
