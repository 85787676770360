import DetailNavTitle from '@/components/business/detail-nav-title';
import React, {useState, useEffect} from 'react';
import BonusItem from './bonus-item';
import {goBack} from '@/utils';
import SelectDate from '@/common-pages/transaction/components/date-selected';
import {useLoadMore} from '@/common-pages/hooks/load-more.hooks';
import {InfiniteScroll} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {getTaskCompletedList} from '../service';
import dayjs from 'dayjs';
import NoData from '@/components/basic/error-pages/no-data';

const BonusHistory: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [pageSize, setPageSize] = useState(10);
  const {t} = useTranslation();

  const {loading, list, loadMore, hasMore, reload} = useLoadMore(pageNo => {
    const startTime = +dayjs(currentDate).startOf('month');
    const endTime = +dayjs(currentDate).endOf('month');

    return getTaskCompletedList({
      pageNum: pageNo,
      pageSize: pageSize,
      startTime,
      endTime,
    }).then(res => {
      if (pageNo === 1) {
        setPageSize(res.pageSize);
      }
      return {
        list: res.list,
        total: res.total,
      };
    });
  }, pageSize);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]); // 当日期改变时重新加载数据

  return (
    <div className="flex flex-col h-screen w-full bg-bg overflow-hidden">
      <DetailNavTitle
        title="Bonus History"
        onBack={goBack}
        serverRight
        hideAmount
      />
      <div className="mt-3">
        <SelectDate value={currentDate} onChange={setCurrentDate} />
      </div>
      <Spin style={[theme.flex.flex1, theme.flex.col]} loading={loading}>
        <div className="flex flex-col gap-3 pb-3 px-3 flex-1 overflow-auto">
          {list.map((item, index) => (
            <BonusItem
              key={index}
              title={item.activityName || ''}
              bonusType={item.awardType}
              bonus={+(item.awardValue || 0)}
              claimTime={
                dayjs(item.updateTime).format('DD/MM/YYYY HH:mm') || ''
              }
              status={item.progressStatus || ''}
            />
          ))}
          {list.length > 0 ? (
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
              {hasMore ? t('label.loading') : t('label.noMore')}
            </InfiniteScroll>
          ) : (
            <NoData />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default BonusHistory;
