import globalStore from '@/services/global.state';
import React, {useCallback} from 'react';
import {
  useInfiniteScroll as useInfiniteScrollAhooks,
  useDebounceFn,
} from 'ahooks';

const useInfiniteScroll = (id: string) => {
  const onEndReachedCalledDuringMomentum = React.useRef(true);

  React.useEffect(() => {
    const handleScroll = () => {
      onEndReachedCalledDuringMomentum.current = false;
    };

    if (globalStore.isWeb) {
      const scrollbox = document.getElementById(id);
      scrollbox?.addEventListener('scroll', handleScroll);

      return () => {
        scrollbox?.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    onEndReachedCalledDuringMomentum,
  };
};

export function useLoadMore<T>(
  requestFn: (
    pageNum: number,
    pageSize: number,
  ) => Promise<{list: T[]; total: number}>,
  pageSize: number = 10,
) {
  const {data, loading, loadMoreAsync, reload} = useInfiniteScrollAhooks<{
    list: T[];
    total: number;
  }>(
    d => {
      const page = d ? Math.ceil(d.list.length / pageSize) + 1 : 1;
      return requestFn(page, pageSize);
    },
    {
      manual: true,
    },
  );
  const {run: debounceReload} = useDebounceFn(reload, {wait: 100});
  const hasMore = !!data && data.list.length < data.total;
  const loadMore = useCallback(async () => {
    await loadMoreAsync();
  }, [loadMoreAsync]);
  return {
    list: data?.list || [],
    loading,
    loadMoreAsync,
    loadMore,
    reload: debounceReload,
    hasMore,
  };
}

export default useInfiniteScroll;
