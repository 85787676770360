import React, {useEffect, useMemo, useState} from 'react';
import {ActivityDetailResponse} from '../../type';
// import globalStore from '@/services/global.state';
import {Button, Image} from 'antd-mobile';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack, replace} from '@/utils';
import {useTranslation} from 'react-i18next';
import styles from '../common.module.scss';
import dayjs from 'dayjs';
import {CountDown} from '../common';
import CurrentRecharge from './current-recharge';
import {useLogin} from '@/utils/state-hooks';
import RewardsCard from './rewards-card';
interface RechargeRebateTemplateData {
  backgroundPic?: string;
  backgroundColor?: string;
  eventRule?: string;
  buttonLinear?: string;
  eventRewards?: string;
}

const RechargeRebate = () => {
  const [data, setData] = useState<ActivityDetailResponse>();

  // const lang = globalStore.lang;
  useEffect(() => {
    const _data = localStorage.getItem('promotion-detail-v2');
    if (_data) {
      try {
        setData(JSON.parse(_data));
      } catch (error) {
        console.error('parse error', error);
      }
    }
  }, []);
  const templateData = useMemo<RechargeRebateTemplateData>(() => {
    if (!data) {
      return {};
    }
    try {
      let json = data.templateVariableJson || '{}';
      while (typeof json === 'string') {
        json = JSON.parse(json);
      }
      return json;
    } catch (error) {
      console.info('parse templateVariableJson error');
      return {};
    }
  }, [data]);
  const calcActivityTime = useMemo(() => {
    return (
      dayjs(data?.activityInfo?.startTime).format('DD/MM YYYY') +
      ' - ' +
      dayjs(data?.activityInfo?.endTime).format('DD/MM YYYY')
    );
  }, [data]);
  const login = useLogin();

  const currentCount = useMemo(() => {
    if (!login) {
      return -1;
    }
    const groupTypeList = [
      'RECHARGE_NOT',
      'RECHARGE_FIRST',
      'RECHARGE_SECOND',
      'RECHARGE_THIRD',
      'RECHARGE_FOUR',
    ];
    for (let i = 0; i < groupTypeList.length; i++) {
      const item = groupTypeList[i];
      if (data?.activityInfo?.presetGroupTypeList?.includes(item)) {
        return i;
      }
    }
    return -1;
  }, [data?.activityInfo?.presetGroupTypeList, login]);
  const {t} = useTranslation();
  return (
    <div className="flex flex-col w-auto h-screen overflow-hidden relative">
      <DetailNavTitle
        onBack={goBack}
        title={t('promotionsV2.details')}
        serverRight
        hideAmount
      />
      <div
        className="flex flex-col flex-1 overflow-hidden relative"
        style={{
          background: templateData.backgroundColor,
        }}>
        <Image
          className="absolute top-0 left-0 w-full"
          src={templateData.backgroundPic}
          fallback={
            <div
              style={{
                background: templateData.backgroundColor,
              }}
            />
          }
        />
        <div className="flex flex-col mt-[9.15625rem] flex-1 z-[5] overflow-hidden">
          <div className="flex flex-row items-center justify-between px-5 pt-2 pb-4">
            <div className="flex flex-col text-white text-xs gap-1">
              <span>{t('promotionsV2.activityTime')}</span>
              <span className="font-bold">{calcActivityTime}</span>
            </div>
            <div className="flex flex-col text-white text-xs items-end gap-1">
              <span>{t('promotionsV2.remainTime')}</span>
              <CountDown
                endTime={data?.activityInfo?.endTime}
                onEnd={() => {}}
              />
            </div>
          </div>
          <div className="h-0 mx-4 border-t border-t-white/30" />
          <CurrentRecharge
            currentCount={currentCount != null ? currentCount : -1}
            eventRewards={templateData.eventRewards || ''}
          />

          <div className="flex flex-col flex-1 overflow-auto gap-2">
            <RewardsCard
              currentCount={currentCount != null ? currentCount : -1}
              data={templateData.eventRewards || ''}
            />
            <div className="mx-4 bg-white rounded-xl flex flex-col p-3 gap-3">
              <div className="flex flex-row items-center gap-2">
                <div className="w-1 h-3 bg-c1" />
                <div className="text-t1 text-sm font-bold capitalize">
                  {t('promotionsV2.rules')}
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 gap-2 ${styles['event-rule']}`}
                dangerouslySetInnerHTML={{__html: templateData.eventRule || ''}}
              />
            </div>
            <div className="h-[5.75rem] flex-none" />
          </div>

          <div
            className="absolute bottom-0 left-0 right-0 pt-8 pb-3 px-5"
            style={{
              background: templateData.buttonLinear || undefined,
            }}>
            <Button
              className="rounded-full bg-c1 text-white text-base font-bold w-full h-12 flex items-center justify-center before:rounded-full uppercase"
              onClick={() => {
                if (!login) {
                  replace('Login');
                } else {
                  replace('Recharge');
                }
              }}>
              {t('promotionsV2.joinNow')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeRebate;
