import {homePage} from '@/config';
import {goBack, goCS, goTo} from '@/utils';
import {useLinkedTabs} from '@/utils/hooks';
import {useLogin} from '@/utils/state-hooks';
import {ReactComponent as ChavronLeft} from '@assets/icons/chavron-left.svg';
import {ReactComponent as Service} from '@assets/icons/service.svg';
import {Button, Tabs} from 'antd-mobile';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
interface NavTitleProps {
  onTabChange?: (tab: string) => void;
}

const NavTitle = ({onTabChange}: NavTitleProps) => {
  const {t} = useTranslation();

  const {tabKey, onTabChange: handleTabChange} = useLinkedTabs({
    routeParamKey: 'tabKey',
    tabsData: [{tabKey: 'bonus'}, {tabKey: 'promotion'}],
    onTabChange: tab => {
      onTabChange?.(tab);
    },
  });

  const login = useLogin();

  useEffect(() => {
    if (tabKey === 'bonus' && !login) {
      goTo('Login', {backPage: homePage, successPage: homePage});
    }
  }, [tabKey, login]);

  return (
    <div
      className="flex flex-row items-center justify-between w-full py-2.5 px-3 flex-none bg-white z-50"
      style={{boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)'}}>
      <Button className="bg-transparent p-0" onClick={goBack}>
        <ChavronLeft className="size-6 text-t2" />
      </Button>
      <Tabs
        className="base-tabs"
        activeLineMode="fixed"
        activeKey={tabKey}
        onChange={handleTabChange}>
        <Tabs.Tab title={t('promotionsV2.bonus')} key="bonus" />
        <Tabs.Tab title={t('promotionsV2.promotion')} key="promotion" />
      </Tabs>
      <Button className="bg-transparent p-0" onClick={() => goCS()}>
        <Service className="size-6" />
      </Button>
    </div>
  );
};
export default NavTitle;
