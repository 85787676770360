import React from 'react';
import {ReactComponent as DeleteSvg} from '@assets/icons/deleteSvg.svg';
type NumberKeyBoardProps = {
  onChange: (val: number) => void;
  onDelete: () => void;
};
function NumBerKeyBoard(props: NumberKeyBoardProps) {
  const {onChange, onDelete} = props;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '-', 0, 'delete'];
  return (
    <div className="px-1.5 grid grid-cols-3 gap-1.5">
      {numbers.map((item, i) => {
        if (item === '-') {
          return <div key={i} className="h-[2.875rem]" />;
        }
        if (item === 'delete') {
          return (
            <div
              key={i}
              className="h-[2.875rem] flex items-center justify-center active:brightness-90 active:bg-[#fcfcfe]"
              onClick={onDelete}>
              <DeleteSvg width={24} height={24} />
            </div>
          );
        }
        return (
          <div
            key={i}
            onClick={() => onChange(item as number)}
            className="flex justify-center items-center bg-[#fcfcfe] font-normal text-t1 rounded-[5px] shadow shadow-[#898a8d] h-[2.875rem] text-[1.5625rem] py-2 active:brightness-90">
            {item}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(NumBerKeyBoard);
