import theme from '@/style';
import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import BackgroundTimer from '@components/utils/timer';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import './quick-digit-header-status.css';
import Video from '@basicComponents/video';
import CutDown from '@businessComponents/games/cut-down';
import useRenderRemainTime from '@/common-pages/pick-digit/components/quick-digit-header-status/useRenderRemainTime';
interface QuickDigitHeaderStatusProps {
  minBetSeconds?: number;
  remainingTime?: number;
  onTimeEnd?: () => void;
  onTimeChange?: (remainMillis: number) => void;
  issueNo?: string;
  timeVersion?: number;
  hasCutdown?: boolean;
}
const QuickDigitHeaderStatus = ({
  minBetSeconds = 30,
  remainingTime = 0,
  onTimeEnd,
  onTimeChange,
  issueNo,
  timeVersion,
  hasCutdown,
}: QuickDigitHeaderStatusProps) => {
  const {t} = useTranslation();
  const timer = React.useRef<SafeAny>(null);
  const [endTimeMillis, setEndTimeMillis] = useState(0);
  const [remainMillis, setRemainMillis] = useState(0);
  const {calcActualSize} = useScreenSize();
  const [showCutdown, setShowCutdown] = useState(false);
  const [cutdownPaused, setCutdownPaused] = useState(false);
  const {render} = useRenderRemainTime({remainMillis, size: 16});

  useEffect(() => {
    setEndTimeMillis(Date.now() + remainingTime * 1000);
  }, [remainingTime, timeVersion]);

  React.useEffect(() => {
    if (remainingTime <= 0) {
      timer.current && BackgroundTimer.clearInterval(timer.current);
      return;
    }
    timer.current = BackgroundTimer.setInterval(() => {
      const _remainMillis = endTimeMillis - Date.now();
      if (_remainMillis < 3 * 1000) {
        setShowCutdown(true);
        setCutdownPaused(false);
      }
      if (_remainMillis <= 0) {
        timer.current && BackgroundTimer.clearInterval(timer.current);
        onTimeEnd?.();
      }
      onTimeChange?.(Math.max(_remainMillis, 0));
      setRemainMillis(Math.max(_remainMillis, 0));
    }, 100);
    return () => timer.current && BackgroundTimer.clearInterval(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeMillis, issueNo]);
  const status = React.useMemo(() => {
    if (remainMillis < 4 * 1000) {
      return 'countdown';
    }
    if (remainMillis < minBetSeconds * 1000) {
      return 'drawing';
    }
    return 'purchasing';
  }, [remainMillis, minBetSeconds]);

  const styles = StyleSheet.create({
    remainTime: {
      gap: calcActualSize(4),
      top: '50%',
      left: '50%',
      transform: [{translateX: '-50%'}, {translateY: '-50%'}] as any[],
    },
  });

  return (
    <View style={[theme.flex.col, {padding: calcActualSize(4)}]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.position.rel,
          {
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            borderRadius: 9999,
            paddingHorizontal: calcActualSize(8),
            paddingVertical: calcActualSize(4),
          },
        ]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {gap: calcActualSize(8)},
          ]}>
          <div
            className={`quick-digit-header-status-indicator ${
              status !== 'purchasing' ? 'status-drawing' : ''
            }`}
          />
          <div className="text-sm text-white font-medium">
            {t(`game-page.label.${status}`)}
          </div>
        </View>
        <Text calc size="medium" color="white">
          {issueNo}
        </Text>

        <View
          style={[
            theme.position.abs,
            theme.flex.row,
            theme.flex.centerByCol,
            styles.remainTime,
          ]}>
          {render()}
        </View>
      </View>
      {hasCutdown && (
        <Video
          onEnd={() => {
            setCutdownPaused(true);
            // videoRef.current?.seek(0);
          }}
          audioOnly
          source={require('@components/assets/music/cut-down.mp3')}
          src={'/assets/mp3/cut-down.mp3'}
          paused={cutdownPaused}
        />
      )}

      {hasCutdown && (
        <CutDown
          visible={showCutdown}
          onClose={() => {
            setShowCutdown(false);
            setCutdownPaused(false);
          }}
        />
      )}
    </View>
  );
};

export default QuickDigitHeaderStatus;
