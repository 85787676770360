import React from 'react';
import HomeFloorBox from '../home-floor-box';
import {useTranslation} from 'react-i18next';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {LazyImageBackground} from '@/components/basic/image';
import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import {Image, View} from 'react-native';
import {MatkaListItem} from '../../home.type';
import {Shadow} from 'react-native-shadow-2';
import {BasicObject} from '@/types';
import {goTo, useResponsiveDimensions, getNumberRates} from '@/utils';
import {SATTA_GAME_BG, SATTA_GAME_ICON} from '../../games';
import Price from '@/components/business/price';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {UserPlay} from '@/components/business/user-play/user-play';

const shadow = {
  startColor: 'rgba(0, 0, 0, 0.25)',
  distance: 4,
  offset: [-2, 2],
  style: [
    theme.fill.fillW,
    theme.padding.tbxs,
    theme.padding.lrs,
    theme.flex.center,
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({boxShadow: '-2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset'});
}

const getResultNum = (
  openResultNum?: string | null,
  closeResultNum?: string | null,
) => {
  let openNum, closeNum;
  if (openResultNum) {
    openNum = openResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    openNum = '*';
  }
  if (closeResultNum) {
    closeNum = closeResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    closeNum = '*';
  }
  return {openNum, closeNum};
};

interface SattaProps {
  list: MatkaListItem[];
  serverTime: number;
}

const HomeLotterySatta = ({list = [], serverTime}: SattaProps) => {
  const i18n = useTranslation();
  const {width} = useResponsiveDimensions();
  const calc = width / 375;
  // const gap = 6;
  const itemHeight = 134 * calc;
  // const itemWidth = (width - gap * 6) / 3;
  const itemWidth = 104 * calc;

  const [targetList, setTargetList] = React.useState<
    (MatkaListItem & {
      closeNum: string | number;
      openNum: string | number;
    })[][]
  >([]);

  React.useEffect(() => {
    const sattaList = list.map(item => {
      const {openNum, closeNum} = getResultNum(
        item.openResultNum,
        item.closeResultNum,
      );
      return {
        ...item,
        openNum,
        closeNum,
        backImgWeb: SATTA_GAME_BG[item.lotteryName],
        backgroundImage: SATTA_GAME_ICON[item.lotteryName],
      };
    });

    if (sattaList.length * 140 > width * 2) {
      const halfNum = Math.ceil(sattaList.length / 2);
      const arr1 = sattaList;
      const arr2 = sattaList.splice(halfNum);
      setTargetList(arr1.map((v, i) => [v, arr2[i]]));
    } else {
      setTargetList(sattaList.map(v => [v]));
    }
  }, [list, width]);

  return (
    <HomeFloorBox
      title={i18n.t('home.matka.title')}
      list={targetList}
      color="rgba(202, 195, 243, 0.50)"
      hasRightPadding={false}
      rightDom={<UserPlay number={getNumberRates('MATKA')} />}
      renderItem={(arr, index) => {
        return (
          <View key={index}>
            {arr?.map((item, itemIndex) => {
              return item === null || item === undefined ? (
                <View key={index + '' + itemIndex} />
              ) : (
                <ExposureWrap
                  key={index + '' + itemIndex}
                  message={`HOME_SATTA_${index + 1}_EXPOSURE`}>
                  <NativeTouchableOpacity
                    style={[
                      {
                        height: itemHeight,
                        width: itemWidth,
                      },
                      theme.background.palegrey,
                      theme.borderRadius.m,
                      theme.overflow.hidden,
                      theme.position.rel,
                      itemIndex !== 0 && theme.margin.topxs,
                      index !== 0 && theme.margin.leftxs,
                    ]}
                    disabled={!item}
                    onPress={() => {
                      trackClick(`HOME_SATTA_${index + 1}_TAB`);
                      if (!serverTime) {
                        return globalStore.globalWaringTotal(
                          i18n.t('warning.initializin'),
                        );
                      }
                      if (!item) {
                        return;
                      }
                      if (item.isClose === 2) {
                        globalStore.globalTotal.next({
                          type: 'warning',
                          message: i18n.t('home.tip.closed', {
                            name: item.lotteryName,
                          }),
                        });
                        return;
                      }
                      // goTo('GameWebView', {
                      //   type: 'matka',
                      //   params: `data=${JSON.stringify(item)}`,
                      // });
                      goTo('GameSatta', {
                        id: item.id,
                        lotteryName: item.lotteryName,
                      });
                    }}
                    key={index + '' + itemIndex}>
                    {item && (
                      <>
                        <LazyImageBackground
                          height={(134 - 30) * calc}
                          width={itemWidth}
                          imageUrl={item.backgroundImage}
                          style={[theme.flex.end, theme.flex.centerByCol]}>
                          <View
                            style={[
                              theme.overflow.hidden,
                              // eslint-disable-next-line react-native/no-inline-styles
                              {
                                borderRadius: 32,
                                borderWidth: 1,
                                borderColor: 'rgba(255, 255, 255, 0.20)',
                                backgroundColor: 'rgba(0, 0, 0, 0.30)',
                              },
                            ]}>
                            <Shadow {...shadow}>
                              <Text
                                calc
                                blod
                                style={[
                                  theme.font.white,
                                  // eslint-disable-next-line react-native/no-inline-styles
                                  {
                                    textShadowColor: 'rgba(0, 0, 0, 0.5)',
                                    textShadowOffset: {width: 1, height: 1},
                                    textShadowRadius: 1,
                                  },
                                ]}>
                                {item.openResultNum || '***'}-{item.openNum}
                                {item.closeNum}-{item.closeResultNum || '***'}
                              </Text>
                            </Shadow>
                          </View>
                          <View
                            style={[
                              theme.flex.row,
                              theme.flex.centerByCol,
                              theme.margin.tbl,
                            ]}>
                            <Price
                              textProps={{
                                calc: true,
                                fontSize: 9,
                                color: theme.basicColor.white,
                              }}
                              price={item.minPrice}
                              fixed={0}
                              thousands={false}
                            />
                            <Text
                              calc
                              fontSize={9}
                              fontFamily="fontDin"
                              blod
                              color={theme.basicColor.white}>
                              /BID-WIN
                            </Text>
                            <Price
                              textProps={{
                                calc: true,
                                fontSize: 9,
                                color: theme.basicColor.white,
                              }}
                              fixed={0}
                              thousands={false}
                              price={item.wonAmount * 10000}
                            />
                          </View>
                        </LazyImageBackground>
                        <View
                          style={[
                            theme.flex.flex1,
                            theme.padding.lrs,
                            theme.background.white,
                            theme.flex.center,
                          ]}>
                          <View
                            style={[
                              theme.fill.fillW,
                              theme.flex.row,
                              theme.flex.centerByCol,
                              theme.flex.between,
                            ]}>
                            <Text calc fontSize={8} second>
                              {i18n.t('home.matka.open')}:
                            </Text>
                            <Text calc fontSize={8} second>
                              {item.openDraw}
                            </Text>
                          </View>
                          <View
                            style={[
                              theme.fill.fillW,
                              theme.flex.row,
                              theme.flex.centerByCol,
                              theme.flex.between,
                            ]}>
                            <Text calc fontSize={8} second>
                              {i18n.t('home.matka.close')}:
                            </Text>
                            <Text calc fontSize={8} second>
                              {item.closeDraw}
                            </Text>
                          </View>
                        </View>
                        {item.isClose === 2 && (
                          <View
                            style={[
                              theme.fill.fill,
                              theme.position.abs,
                              {
                                backgroundColor:
                                  theme.basicColor.white + 'fff49',
                              },
                            ]}>
                            <Image
                              source={require('@assets/icons/home/closed.webp')}
                              style={[
                                theme.image.m,
                                theme.position.abs,
                                {
                                  right: theme.paddingSize.s,
                                  top: theme.paddingSize.s,
                                },
                              ]}
                            />
                          </View>
                        )}
                      </>
                    )}
                  </NativeTouchableOpacity>
                </ExposureWrap>
              );
            })}
          </View>
        );
      }}
    />
  );
};

export default React.memo(HomeLotterySatta);
