import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as SendIcon} from '@assets/icons/sendMessage.svg';
import {ReactComponent as NumberIcon} from '@assets/icons/number.svg';
import {ReactComponent as QuickIcon} from '@assets/icons/quickMessage.svg';
import {ReactComponent as EmojiIcon} from '@assets/icons/emij.svg';
import NumBerKeyBoard from '@/common-pages/pick-digit/quick/num-berKey-board';
import Emoji from '@/common-pages/pick-digit/quick/emoji';
import {ScrollView, Animated} from 'react-native';
import globalStore from '@services/global.state';
import {getQuickList, ReplyType} from '@/common-pages/im/im.service';

const tabList = [
  {
    icon: NumberIcon,
    title: 'number',
  },
  {
    icon: QuickIcon,
    title: 'quick',
  },
  {
    icon: EmojiIcon,
    title: 'emoji',
  },
];
type ChatBottomSendProps = {
  onClose?: () => void;
};

function ChatBottomSend(props: ChatBottomSendProps) {
  const {onClose} = props;
  const [active, setActive] = useState('number');
  const [inputVal, setInputVal] = useState('');
  const transAni = useRef(new Animated.Value(0)).current;
  // const [visible, setVisible] = useState(false);
  const [messageList, setMessageList] = useState<ReplyType[]>([]);
  const send = (text: string) => {
    // sendMessage
    if (!text) return;
    globalStore.chatMessage.next({
      message: text,
    });
    setTimeout(() => {
      showHide(false);
    }, 400);
  };
  const showHide = (show: boolean) => {
    Animated.timing(transAni, {
      toValue: show ? 1 : 0,
      duration: 240,
      useNativeDriver: true,
    }).start(() => {
      if (!show) {
        onClose?.();
      }
    });
  };
  const handleChangeTab = (val: string) => {
    if (val === active) return;
    setInputVal('');
    setActive(val);
  };
  const activeClass =
    'after:w-[72px] after:-bottom-1.5 after:absolute after:block after:left-1/2 after:bg-[#1d9d00] after:h-1 after:-translate-x-1/2 after:bottom-[-10px]';
  useEffect(() => {
    showHide(true);
    getQuickList(3).then(res => {
      setMessageList(res.content);
    });
    // eslint-disable-next-line
  }, []);
  const renderQuickMessage = () => {
    return (
      <div className="px-3 flex flex-col gap-2">
        {messageList.map(item => {
          return (
            <div
              onClick={() => send(item.content)}
              className="bg-[#FCFCFE] rounded-[5px] shadow shadow-[#898A8D] text-t2 text-sm p-2 active:brightness-90"
              key={item.id}>
              {item.content}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="absolute w-full h-full bg-black/30 z-[9999]">
      <div className="w-full h-full " onClick={() => showHide(false)}></div>
      <Animated.View
        style={{
          width: '100%',
          bottom: 0,
          position: 'absolute',
          transform: [
            {
              translateY: transAni.interpolate({
                inputRange: [0, 1],
                outputRange: [1000, 0],
              }),
            },
          ],
        }}>
        <div className="bg-white w-full pt-[6px]">
          <div className="px-3 h-[2.875rem] w-full flex gap-1">
            <div className="flex-1 rounded-full bg-[#f5f5f5] flex items-center px-3 overflow-x-auto">
              <div className="flex-1 flex h-[1.5rem] text-base whitespace-nowrap bg-transparent outline-transparent">
                <span className="input-caret">{inputVal}</span>
              </div>
              {active === 'number' ? (
                <span className="ml-auto text-t3 text-base font-normal">
                  {inputVal.length}/3
                </span>
              ) : null}
            </div>
            <div
              className="rounded-full w-[2.875rem] bg-[#1D9D00] flex justify-center items-center"
              onClick={() => send(inputVal)}>
              <SendIcon width="1.5rem" height="1.5rem" />
            </div>
          </div>
          <div className="flex items-center py-1.5 h-11">
            {tabList.map(item => {
              return (
                <div
                  onClick={() => handleChangeTab(item.title)}
                  className={`flex-1 flex justify-center relative ${
                    item.title === active && activeClass
                  }`}
                  key={item.title}>
                  <item.icon width="1.5rem" height="1.5rem" />
                </div>
              );
            })}
          </div>
          <div className="bg-[#D1D5DB] h-[15.625rem] py-2">
            <ScrollView style={{height: '100%'}}>
              {active === 'number' && (
                <NumBerKeyBoard
                  onDelete={() => {
                    setInputVal(inputVal.slice(0, -1));
                  }}
                  onChange={val => {
                    if (inputVal.length >= 3) return;
                    setInputVal(inputVal + val);
                  }}></NumBerKeyBoard>
              )}
              {active === 'quick' && renderQuickMessage()}
              {active === 'emoji' && (
                <Emoji
                  onChange={val => {
                    if (inputVal.length >= 60) {
                      return;
                    }
                    setInputVal(inputVal + val);
                  }}></Emoji>
              )}
            </ScrollView>
          </div>
        </div>
      </Animated.View>
    </div>
  );
}

export default React.memo(ChatBottomSend);
