import React, {useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {useWebView} from '../hooks/webview.hooks';
import globalStore from '@/services/global.state';
import qs from 'qs';
import {EventType, useChatMessage} from '@/common-pages/im/useChatMessage';
import {packageId} from '@/config';
import {envConfig} from '@/utils/env.config';
import {getGroupList} from '@/common-pages/im/im.service';
import NotLoggedPage from '@/common-pages/im/not-logged-page';
import {getAdjustAttribution} from '@utils/adjust';

const IMWebView = () => {
  const {pageLoading, setPageLoading, onMessage} = useChatMessage();
  const tokenRef = useRef('');
  const [token, setToken] = useState('');
  const [version, setVersion] = useState(0);
  const [hasRoom, setHasRoom] = useState(true);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setToken(token || '');
    });
    const chatSub = globalStore.chatMessage.subscribe(message => {
      postMessage &&
        postMessage({
          source: 'chat-room',
          payload: {
            event: EventType.Message,
            data: {
              message: message.message,
            },
          },
        });
    });
    const chatLocalSub = globalStore.chatInsertMessage.subscribe(message => {
      postMessage &&
        postMessage({
          source: 'chat-room',
          payload: {
            event: EventType.IM_LOCAL_MESSAGE,
            data: {
              sharedData: message.message,
            },
          },
        });
    });
    tokenRef.current = localStorage.getItem('token') || '';
    return () => {
      sub.unsubscribe();
      chatSub.unsubscribe();
      chatLocalSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [url, setUrl] = useState('');

  useEffect(() => {
    (async () => {
      try {
        if (!token) return;
        const res = await getGroupList();
        const roomId = res.find(item => item.gameCodes === 'quick3d,')?.groupId;
        if (!roomId) {
          setHasRoom(false);
          setPageLoading(false);
          return;
        }
        const qsParams = {
          packageId: packageId,
          originToken: token,
          isVisitor: !token,
          deviceCode: getAdjustAttribution().adid,
          full: 2,
          t: Date.now(),
          chatMode: 'inside',
        };
        const queryString = qs.stringify(qsParams);
        let _url = `${envConfig.IMURL}/#/chat/${roomId}/1?${queryString}`;
        setUrl(_url);
        setHasRoom(true);
      } catch (error) {
        setHasRoom(false);
        setPageLoading(false);
      }
    })();
  }, [setPageLoading, token]);

  const {render, postMessage} = useWebView({
    originUrl: url,
    onMessage: onMessage,
    onLoadEnd: () => {
      setPageLoading(false);
    },
  });

  useEffect(() => {
    if (token !== tokenRef.current) {
      setVersion(v => v + 1);
      tokenRef.current = token;
    }
  }, [token]);

  return (
    <View key={version} style={[theme.fill.fill, theme.flex.col]}>
      {token ? (
        <Spin loading={pageLoading} style={[theme.flex.flex1]}>
          {hasRoom ? render : null}
        </Spin>
      ) : (
        <NotLoggedPage />
      )}
    </View>
  );
};

export default IMWebView;
