import {View, StyleSheet, TextInput} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import React from 'react';
import {Svg, Rect, Path} from 'react-native-svg';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';

export interface NumberStepperProps {
  rate?: string;
  onChangeRate?: (v: string) => void;
  onStepChange?: (v: number) => void;
  onFocusStateChange?: (status: boolean) => void;
}

const NumberStepper = ({
  rate = '3',
  onChangeRate = () => {},
  onStepChange = () => {},
  onFocusStateChange,
}: NumberStepperProps) => {
  return (
    <View style={[theme.flex.row, styles.container]}>
      <NativeTouchableOpacity
        style={styles.btn}
        onPress={() => {
          if (rate) {
            const result = Number(rate) - 1;
            onStepChange(result);
          }
        }}>
        <LinearGradient
          colors={['#F0F3FF', '#DDE0E9']}
          style={[theme.flex.flex1, theme.flex.center]}>
          <Svg width={9} height={2}>
            <Rect x="0.5" width="8" height="2" rx="1" fill="#5F6975" />
          </Svg>
        </LinearGradient>
      </NativeTouchableOpacity>
      <View style={styles.inputContainer}>
        <TextInput
          onFocus={() => onFocusStateChange?.(true)}
          onBlur={() => onFocusStateChange?.(false)}
          value={rate}
          onChangeText={v => {
            const regex = /^[0-9]*$/;
            if (regex.test(v)) {
              onChangeRate(v);
            }
          }}
          maxLength={3}
          textAlign={'center'}
          style={styles.input}
          keyboardType="numeric"
        />
      </View>
      <NativeTouchableOpacity
        style={styles.btn}
        onPress={() => {
          if (rate) {
            const result = Number(rate) + 1;
            onStepChange(result);
          }
        }}>
        <LinearGradient
          colors={['#F0F3FF', '#DDE0E9']}
          style={[theme.flex.flex1, theme.flex.center]}>
          <Svg width={11} height={10}>
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.50001 9C4.50001 9.55229 4.94773 10 5.50001 10C6.0523 10 6.50001 9.55228 6.50001 9V6.00018H9.5C10.0523 6.00018 10.5 5.55247 10.5 5.00018C10.5 4.4479 10.0523 4.00018 9.5 4.00018H6.50001V1C6.50001 0.447715 6.0523 0 5.50001 0C4.94773 0 4.50001 0.447716 4.50001 1V4.00018H1.5C0.947715 4.00018 0.5 4.4479 0.5 5.00018C0.5 5.55247 0.947716 6.00018 1.5 6.00018H4.50001V9Z"
              fill="#5F6975"
            />
          </Svg>
        </LinearGradient>
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E6E9F4',
    alignItems: 'center',
    borderRadius: 14,
    height: 28,
  },
  btn: {
    width: 28,
    height: 28,
    borderRadius: 14,
    overflow: 'hidden',
    elevation: 1,
  },
  input: {
    flex: 1,
    padding: 0,
    textAlign: 'center',
    fontFamily: 'fontInterBold',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.fontColor.main,
  },
  inputContainer: {
    paddingHorizontal: 8,
    height: '100%',
    width: 40,
  },
});

export default NumberStepper;
