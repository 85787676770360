import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Overlay} from '@rneui/themed';
import theme from '@/style';
import React, {useState} from 'react';
import {tokenRoutes} from '@/app.service';
import globalStore from '@/services/global.state';
import {getUrlParams, goTo} from '@utils';
import {Linking, ImageBackground, Image, View, StyleSheet} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import {IListEle} from '@/app.service';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import {goToUrl} from '@/common-pages/game-navigate';

const ICON_TOP = -44;
const IDOT_BTM = -20;
const GAP = 4;
const MODEL_WIDTH = 320;
const MODEL_HEIGHT = 426;
const NO_COLOR = 'rgba(0,0,0,0)';

export interface PromotionModalProps {
  popVisible: boolean;
  setPopVisible: React.Dispatch<React.SetStateAction<boolean>>;
  list: IListEle[];
}

const PromotionModal = ({
  popVisible,
  setPopVisible,
  list,
}: PromotionModalProps) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const container = calcActualSize(MODEL_WIDTH + 2 * GAP);
  const modalWidth = calcActualSize(MODEL_WIDTH);
  const modalHieght = calcActualSize(MODEL_HEIGHT);
  const styles = StyleSheet.create({
    idotBox: {
      bottom: calcActualSize(IDOT_BTM),
      gap: calcActualSize(theme.paddingSize.s),
    },
    idot: {
      width: calcActualSize(theme.paddingSize.s),
      height: calcActualSize(theme.paddingSize.s),
      borderRadius: calcActualSize(theme.paddingSize.s / 2),
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    idotActive: {
      backgroundColor: theme.basicColor.primary,
      width: calcActualSize(theme.paddingSize.s),
    },
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [container, setContainer] = useState<{width: number; height: number}>({
  //   width: calcActualSize(MODEL_WIDTH),
  //   height: calcActualSize(MODEL_HEIGHT),
  // });
  // const getContainer = useCallback(() => {
  //   if (globalStore.isWeb) {
  //     const nImg = new window.Image();
  //     nImg.src = list[0]?.popImg;
  //     nImg.onload = () => {
  //       const {width, height} = nImg;
  //       setContainer({
  //         width: calcActualSize(MODEL_WIDTH),
  //         height: (calcActualSize(MODEL_WIDTH) * height) / width,
  //       });
  //     };
  //   } else {
  //     Image.getSize(list[0]?.popImg, (width, height) => {
  //       setContainer({
  //         width: calcActualSize(MODEL_WIDTH),
  //         height: (calcActualSize(MODEL_WIDTH) * height) / width,
  //       });
  //     });
  //   }
  // }, [list, calcActualSize]);
  // useEffect(() => {
  //   getContainer();
  // }, [getContainer]);
  return (
    <Overlay
      isVisible={popVisible}
      overlayStyle={[
        theme.padding.zorro,
        theme.flex.centerByCol,
        {
          backgroundColor: NO_COLOR,
          shadowColor: 'transparent',
          width: modalWidth,
        },
      ]}>
      <View style={[theme.position.rel, theme.flex.between, theme.fill.fillW]}>
        <View />
        <NativeTouchableOpacity
          style={[
            theme.position.abs,
            {
              right: calcActualSize(theme.paddingSize.xxs),
              top: calcActualSize(ICON_TOP),
            },
          ]}
          onPress={() => {
            setPopVisible(false);
          }}>
          <Image
            style={[
              {
                width: calcActualSize(theme.paddingSize.xxl * 2),
                height: calcActualSize(theme.paddingSize.xxl * 2),
              },
            ]}
            source={require('@assets/icons/home/button-close.png')}
          />
        </NativeTouchableOpacity>
      </View>
      {list.length > 1 && (
        <View
          style={[
            theme.position.abs,
            theme.fill.fillW,
            theme.flex.row,
            theme.flex.center,
            styles.idotBox,
          ]}>
          {list.map((_v, i) => (
            <View
              key={i}
              style={[styles.idot, currentIndex === i && styles.idotActive]}
            />
          ))}
        </View>
      )}
      {list && list.length === 1 && (
        <NativeTouchableOpacity
          activeOpacity={1}
          style={[
            theme.overflow.hidden,
            theme.position.rel,
            // theme.background.white,
            {
              borderRadius: calcActualSize(theme.borderRadiusSize.l * 2),
              width: modalWidth,
              height: modalHieght,
            },
          ]}
          onPress={() => {
            if (list[0].popUrl?.startsWith('route:')) {
              const [route, popParams] = list[0].popUrl.slice(6).split('?');
              const needToken = tokenRoutes.some(r => route.startsWith(r));
              if (!needToken || (needToken && globalStore.token)) {
                goTo(route, getUrlParams(popParams));
              } else {
                goTo('Login', {
                  sucessPage: route,
                  sucessPageParams: popParams,
                });
              }
            } else if (list[0].popUrl?.startsWith('http')) {
              Linking.openURL(list[0].popUrl);
            } else {
              list[0].popUrl && goToUrl(list[0].popUrl);
            }
            setPopVisible(false);
          }}>
          <ImageBackground
            style={[
              {
                width: modalWidth,
                height: modalHieght,
              },
            ]}
            resizeMode={'cover'}
            source={{uri: list[0].popImg}}
          />
          {list[0].popImg &&
            list[0].popImg ===
              'https://download.lotteryanna.com/manager/676d27d364344a14b4f042f44f3014cb.webp' && (
              <View
                style={[
                  theme.position.abs,
                  theme.fill.fillW,
                  theme.flex.flex,
                  theme.flex.center,
                  {
                    bottom: calcActualSize(111),
                  },
                ]}>
                <Text calc accent fontSize={14}>
                  {i18n.t('home.label.imgFail')}
                </Text>
              </View>
            )}
        </NativeTouchableOpacity>
      )}
      {list && list.length > 1 && (
        <Carousel
          loop
          style={[
            theme.overflow.hidden,
            {
              borderRadius: calcActualSize(theme.borderRadiusSize.l * 2),
              paddingHorizontal: calcActualSize(GAP),
            },
          ]}
          width={container}
          height={modalHieght}
          autoPlay={true}
          autoPlayInterval={3000}
          scrollAnimationDuration={1000}
          data={list}
          onProgressChange={e => {
            let index = Math.abs(Math.round(e / container));
            if (index >= list.length) {
              index = 0;
            }
            if (index !== currentIndex) {
              setCurrentIndex(index);
            }
          }}
          renderItem={({item}) => {
            return (
              <NativeTouchableOpacity
                activeOpacity={1}
                style={[
                  theme.overflow.hidden,
                  theme.position.rel,
                  // theme.background.white,
                  {
                    borderRadius: calcActualSize(theme.borderRadiusSize.l * 2),
                    width: modalWidth,
                    height: modalHieght,
                  },
                ]}
                onPress={() => {
                  if (item.popUrl?.startsWith('route:')) {
                    const [route, popParams] = item.popUrl.slice(6).split('?');
                    const needToken = tokenRoutes.some(r =>
                      route.startsWith(r),
                    );
                    if (!needToken || (needToken && globalStore.token)) {
                      goTo(route, getUrlParams(popParams));
                    } else {
                      goTo('Login', {
                        sucessPage: route,
                        sucessPageParams: popParams,
                      });
                    }
                  } else if (item.popUrl?.startsWith('http')) {
                    Linking.openURL(item.popUrl);
                  } else {
                    item.popUrl && goToUrl(item.popUrl);
                  }
                  setPopVisible(false);
                }}>
                <ImageBackground
                  style={[
                    {
                      width: modalWidth,
                      height: modalHieght,
                    },
                  ]}
                  resizeMode={'cover'}
                  source={{uri: item.popImg}}
                />
                {item.popImg &&
                  item.popImg ===
                    'https://download.lotteryanna.com/manager/676d27d364344a14b4f042f44f3014cb.webp' && (
                    <View
                      style={[
                        theme.position.abs,
                        theme.fill.fillW,
                        theme.flex.flex,
                        theme.flex.center,
                        {
                          bottom: calcActualSize(111),
                        },
                      ]}>
                      <Text calc accent fontSize={14}>
                        {i18n.t('home.label.imgFail')}
                      </Text>
                    </View>
                  )}
              </NativeTouchableOpacity>
            );
          }}
        />
      )}
    </Overlay>
  );
};

export default PromotionModal;
