import React, {useMemo} from 'react';
import QuickDigitNumberItem from './quick-digit-number-item';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface QuickDigitNumbersProps {
  nums: string;
  rolling?: boolean;
}

const QuickDigitNumbers = ({nums, rolling}: QuickDigitNumbersProps) => {
  const {calcActualSize} = useScreenSize();
  const delays = useMemo(() => {
    return Array.from({length: 3}).map(_ => Math.random() * 0.2 + 0.1);
  }, []);
  return (
    <div className="flex h-[8.1875rem]">
      {nums
        .slice(0, 3)
        .split('')
        .map((n, i) => (
          <QuickDigitNumberItem
            num={+n}
            key={i}
            rolling={rolling}
            delay={delays[i]}
            itemHeight={calcActualSize((375 / 3 / 127) * 138)}
          />
        ))}
    </div>
  );
};

export default QuickDigitNumbers;
