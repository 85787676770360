import React, {useMemo} from 'react';
import {ReactComponent as TemplateVipLine} from '@/assets/icons/promotions/template-vip-line.svg';
import {Trans, useTranslation} from 'react-i18next';
import {vipSign} from '@/components/business/vip';
import globalStore from '@/services/global.state';
import {toPriceStr} from '@/utils';
import {Button, ProgressBar} from 'antd-mobile';
import styles from '../common.module.scss';
interface VipHeaderCardProps {
  vipLevel: number;
  progressValue?: number;
  bonusResult: number;
  bonusType: string;
  totalProgress: number;
  templateKey?: string;
  onPlay: () => void;
}

const VipHeaderCard: React.FC<VipHeaderCardProps> = ({
  vipLevel,
  bonusResult,
  bonusType,
  totalProgress,
  progressValue,
  templateKey,
  onPlay,
}) => {
  const {t} = useTranslation();
  const calcTotalProgress = useMemo(() => {
    return Math.max(totalProgress, 1);
  }, [totalProgress]);
  const [rwb, ac] = useMemo(() => {
    const [_, _rwb, _ac] = templateKey?.split('_') || [];
    return [_rwb, _ac];
  }, [templateKey]);
  const currentProgress = useMemo(() => {
    let _currentProgress = progressValue || 0;

    return _currentProgress;
  }, [progressValue]);

  const calcCurrentProgress = useMemo(() => {
    return Math.min(currentProgress, calcTotalProgress);
  }, [currentProgress, calcTotalProgress]);

  const progress = useMemo(() => {
    return (calcCurrentProgress / calcTotalProgress) * 100;
  }, [calcCurrentProgress, calcTotalProgress]);

  const tipType = useMemo(() => {
    return !templateKey
      ? '-'
      : rwb === 'RECHARGE'
      ? t('promotionsV2.type.recharge')
      : rwb === 'WITHDRAW'
      ? t('promotionsV2.type.withdraw')
      : t('promotionsV2.type.bet');
  }, [rwb, t, templateKey]);
  const playText = useMemo(() => {
    return rwb === 'RECHARGE'
      ? t('label.deposit')
      : rwb === 'WITHDRAW'
      ? t('label.withdraw')
      : t('label.bet');
  }, [rwb, t]);
  const isAmountType = useMemo(() => {
    return ac.startsWith('AMOUNT');
  }, [ac]);
  const isVip = useMemo(() => {
    return templateKey && templateKey.startsWith('VIP');
  }, [templateKey]);
  const calcBonusAmount = useMemo(() => {
    // 由于数量累计不可能是百分比奖励，所以百分比奖励情况下，进度必然是金额
    return bonusType === 'FIXED_VALUE'
      ? bonusResult || 0
      : (Math.max(currentProgress, calcTotalProgress) * bonusResult) / 100;
  }, [bonusType, bonusResult, calcTotalProgress, currentProgress]);
  return (
    <div
      className={`rounded-xl px-3 pb-3 bg-gradient-to-b from-[#FFFCE3] to-[#FFCA86] flex flex-col ${styles['vip-header-card']}`}>
      <div className="flex flex-row gap-3 items-center h-[5.625rem]">
        {!!isVip && (
          <>
            <div className="flex flex-col gap-1">
              <span className="text-t2 text-xs justify-normal">
                {t('promotionsV2.vipLevel')}
              </span>
              {vipSign(vipLevel)}
            </div>
            <TemplateVipLine className="w-[.3125rem] h-[5.625rem]" />
          </>
        )}
        {!!rwb && rwb === 'RECHARGE' && ac.startsWith('AMOUNT') && (
          <div className="flex-1 flex flex-col items-center gap-2">
            <span className="text-t2 text-xs">
              {t('promotionsV2.depositAmount')}
            </span>
            <span className="text-t1 text-xl font-din font-black">
              <span className="text-xs">{globalStore.currency}</span>
              {toPriceStr(progressValue || 0, {
                showCurrency: false,
                fixed: 0,
                maxFixed: 2,
              })}
            </span>
          </div>
        )}
        {!!rwb && rwb === 'WITHDRAW' && ac.startsWith('AMOUNT') && (
          <div className="flex-1 flex flex-col items-center gap-2">
            <span className="text-t2 text-xs">
              {t('promotionsV2.withdrawAmount')}
            </span>
            <span className="text-t1 text-xl font-din font-black">
              <span className="text-xs">{globalStore.currency}</span>
              {toPriceStr(progressValue || 0, {
                showCurrency: false,
                fixed: 0,
                maxFixed: 2,
              })}
            </span>
          </div>
        )}
        {!!rwb && rwb === 'BET' && (
          <div className="flex-1 flex flex-col items-center gap-2">
            <span className="text-t2 text-xs">
              {t('promotionsV2.cumuBets')}
            </span>
            <span className="text-t1 text-xl font-din font-black">
              <span className="text-xs">{globalStore.currency}</span>
              {toPriceStr(progressValue || 0, {
                showCurrency: false,
                fixed: 0,
                maxFixed: 2,
              })}
            </span>
          </div>
        )}
        {/* 数量要看情况，如果真的需要金额数量都展示，可能是另一个字段 */}
        {!!rwb && rwb === 'RECHARGE' && ac.startsWith('COUNT') && (
          <div className="flex-1 flex flex-col items-center gap-2">
            <span className="text-t2 text-xs">
              {t('promotionsV2.rechargeCount')}
            </span>
            <span className="text-t1 text-xl font-din font-black">
              {progressValue}
            </span>
          </div>
        )}

        {!!rwb && rwb === 'WITHDRAW' && ac.startsWith('COUNT') && (
          <div className="flex-1 flex flex-col items-center gap-2">
            <span className="text-t2 text-xs">
              {t('promotionsV2.withdrawCount')}
            </span>
            <span className="text-t1 text-xl font-din font-black">
              {progressValue}
            </span>
          </div>
        )}

        <div className="flex-1 flex flex-col items-center gap-2">
          <span className="text-t2 text-xs">
            {t('promotionsV2.bonusAmount')}
          </span>
          <span className="text-t1 text-xl font-din font-black">
            <span className="text-sm">{globalStore.currency}</span>
            {toPriceStr(+(calcBonusAmount || 0), {
              suffixUnit: 'K',
              showCurrency: false,
              fixed: 0,
              maxFixed: 2,
            })}
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-3 p-2 rounded-lg bg-white/[0.54]">
        <div className="flex flex-col gap-2 flex-1 overflow-hidden">
          <div className="flex flex-col gap-0.5">
            <ProgressBar percent={progress} />
            <span className="text-t2 text-xs font-bold capitalize">
              <span className="text-c2">
                {isAmountType
                  ? toPriceStr(progressValue, {fixed: 0, maxFixed: 2})
                  : progressValue}{' '}
              </span>
              /{' '}
              {isAmountType
                ? toPriceStr(calcTotalProgress, {fixed: 0, maxFixed: 2})
                : calcTotalProgress}
            </span>
          </div>
          <div className="text-t2 text-[0.625rem] capitalize leading-tight">
            <Trans
              i18nKey={
                isAmountType
                  ? 'promotionsV2.tip.toReceive'
                  : 'promotionsV2.tip.timeToReceive'
              }
              components={[<span className="text-c2 font-normal" />]}
              values={{
                type: tipType,
                rechargeAmount: isAmountType
                  ? toPriceStr(calcTotalProgress - calcCurrentProgress, {
                      suffixUnit: 'K',
                      round: 'ceil',
                      fixed: 0,
                      maxFixed: 2,
                    })
                  : `${calcTotalProgress - calcCurrentProgress}`,
                bonusAmount: toPriceStr(+(calcBonusAmount || 0), {
                  suffixUnit: 'K',
                  fixed: 0,
                  maxFixed: 2,
                }),
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-1 flex-none">
          <span className="text-c2 font-din font-black text-xl">
            <span className="text-sm">{globalStore.currency}</span>
            {toPriceStr(+(calcBonusAmount || 0), {
              suffixUnit: 'K',
              showCurrency: false,
              fixed: 0,
              maxFixed: 2,
            })}
          </span>
          <Button
            className={`rounded-full bg-c1 text-white items-center justify-center h-6 px-3 py-0 text-[0.625rem] min-w-[3.875rem] before:rounded-full ${styles['deposit-button']}`}
            onClick={onPlay}>
            {playText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VipHeaderCard;
