import theme from '@/style';
import React, {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react';
import {View, StyleSheet, Animated, TouchableOpacity} from 'react-native';
import NoData from '@/components/basic/error-pages/no-data';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {ReactComponent as DigitClock} from '@/assets/icons/digit-clock.svg';

export interface CartModalProps {
  onClose?: () => void;
  onClear?: () => void;
  children?: ReactNode;
  isEmpty?: boolean;
  headerTitle?: string | ReactNode;
  EmptyText?: string;
  remingTime?: number;
  stopBetSec: number;
}

export type CartModalRef = {
  closeModal: () => void;
};

const BetModal = forwardRef(
  (
    {
      onClose,
      children,
      remingTime,
      // onClear,
      stopBetSec,
      isEmpty = true,
      headerTitle = ' My BIDS',
      EmptyText = 'All empty',
    }: CartModalProps,
    ref,
  ) => {
    const transAni = React.useRef(new Animated.Value(0)).current;

    const {calcActualSize} = useScreenSize();
    React.useEffect(() => {
      showHide(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      closeModal: () => showHide(false),
    }));

    const showHide = (isShow = false) => {
      Animated.timing(transAni, {
        toValue: isShow ? 1 : 0,
        duration: 240,
        useNativeDriver: true,
      }).start(() => {
        !isShow && onClose?.();
      });
    };
    const styles = StyleSheet.create({
      container: {
        position: 'absolute',
        ...theme.fill.fill,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 9999,
      },
      innerContainer: {
        height: calcActualSize(540),
        backgroundColor: '#f5f5f5',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomColor: 'rgb(230,230,230)',
        borderBottomWidth: 1,
      },
    });
    const reminTime = useMemo(() => {
      return Math.floor((remingTime || 0) / 1000);
    }, [remingTime]);
    useEffect(() => {
      if (reminTime <= stopBetSec) {
        showHide(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminTime, stopBetSec]);
    return (
      <View style={[styles.container]}>
        <TouchableOpacity
          activeOpacity={1}
          style={[theme.flex.flex1]}
          onPress={() => showHide(false)}
        />
        <Animated.View
          style={[
            styles.innerContainer,
            theme.flex.col,
            {
              transform: [
                {
                  translateY: transAni.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1000, 0],
                  }),
                },
              ],
            },
          ]}>
          {reminTime <= stopBetSec && (
            <div
              style={{backdropFilter: 'blur(5px)'}}
              className="bg-black/50 absolute left-0 w-full h-full z-[99999] flex flex-col justify-center items-center gap-3">
              <DigitClock
                width={calcActualSize(38)}
                height={calcActualSize(38)}
              />
              <div className="text-2xl text-[#fde047] font-bold">
                Opening in {reminTime}s
              </div>
            </div>
          )}
          {headerTitle}

          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
            ]}></View>
          <View style={[theme.flex.flex1]}>
            {isEmpty ? <NoData label={EmptyText} /> : children}
          </View>
        </Animated.View>
      </View>
    );
  },
);
export default React.memo(BetModal);
