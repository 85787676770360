import {Badge, Button, Image} from 'antd-mobile';
import React from 'react';
import styles from './home.module.scss';
interface HomeSideBonusProps {
  count: number;
  onClick?: () => void;
}

const HomeSideBonus = ({count, onClick}: HomeSideBonusProps) => {
  return (
    <div className={styles['home-side-bonus']}>
      <Badge content={count > 0 ? Badge.dot : null} color="#F00">
        <Button
          className="p-0 bg-transparent size-16 rounded-full"
          onClick={onClick}>
          <Image src={require('@/assets/icons/home/home-bonus.png')} />
        </Button>
      </Badge>
    </div>
  );
};

export default HomeSideBonus;
