import React from 'react';
import TabHeader from '@/components/business/games/tab-header';

const DigitTab = ({
  type,
  setIndex = () => {},
  index = 0,
  orderNum,
}: {
  type?: string;
  pickThreeId?: number;
  refreshCode?: number;
  index?: number;
  orderNum?: number;
  setIndex?: (v: number, key: string) => void;
}) => {
  const tablist = [
    {
      title: 'result',
      key: 'result',
    },
    {
      title: 'order',
      key: 'order',
    },
  ];
  if (type !== 'normal') {
    tablist.unshift({
      title: 'chat',
      key: 'chat',
    });
  }

  return (
    <TabHeader
      orderNum={orderNum}
      orderKey="order"
      routes={tablist}
      onTabPress={index => {
        setIndex(index, tablist[index].key);
      }}
      index={index || 0}
    />
  );
};

export default React.memo(DigitTab);
