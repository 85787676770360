import React from 'react';

const faceEmoji = [
  [128512, 128580],
  [129296, 129327],
];

const getFaceList = () => {
  const faceList = [];
  for (let i = 0; i < faceEmoji.length; i++) {
    const [start, end] = faceEmoji[i];
    for (let j = start; j <= end; j++) {
      faceList.push({
        context: String.fromCodePoint(j),
        id: j,
      });
    }
  }
  return faceList;
};
function Emoji({onChange}: {onChange: (val: string) => void}) {
  return (
    <div className="grid grid-cols-7 gap-x-6 px-4 gap-y-2">
      {getFaceList().map(item => {
        return (
          <div
            className="text-[1.75rem]"
            onClick={() => onChange(item.context)}>
            {item.context}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(Emoji);
