import React, {useMemo, useRef, useState} from 'react';
import {
  RefreshControl,
  View,
  ScrollView,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from 'react-native';
import theme from '@style';
import globalStore from '@services/global.state';
import HomeMenu from './components/home-menu';
import HomeHeader from './components/home-header';
import Download from './components/download';
import HomeBanner from './components/home-banner';
import HomeSwiperGame from './components/home-swiper-game';
import {NoMoreData} from '@basicComponents/default-page';
import Spin from '@basicComponents/spin';
import Drawer, {DrawerRef} from '@basicComponents/drawer/drawer';
import {BasicObject} from '@/types';
import {getBannerList, getNoticeCheck} from './home.service';
import {useFocusEffect} from '@react-navigation/native';
import HomeTab from './components/home-tab';
import HomeBigWin from './components/home-big-win';
import HomeLottery from './components/lottery/lottery';
import HomeHot from './components/hot/hot';
import HomeScratch from './components/home-scratch';
import HomeLive from './components/home-live';
import HomeCasino from './components/home-casino';
import homeStore from './home.refresh.service';
import {BannerListItem} from './home.type';
import RightMenus from '../casino/components/right-menus';
import {getUserVersion, useResponsiveDimensions} from '@/utils';
import {first} from 'rxjs/operators';
import HomeGoldArea from './components/home-gold-area';
const Home = () => {
  const isA = useMemo(() => getUserVersion(), []);
  const [pageLoading, setPageLoading] = useState(true);
  const menuRef = React.useRef<DrawerRef>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [bannerList, setBannerList] = useState<BannerListItem[]>();
  const [version, setVersion] = useState(0);
  const {height: screenHeight} = useResponsiveDimensions();
  const scrollRef = useRef<ScrollView>(null);
  const setMenuOpen = (bool: boolean, _unreadCount?: number) => {
    if (bool) {
      setUnreadCount(_unreadCount || 0);
      menuRef.current?.open();
    } else {
      menuRef.current?.close();
    }
  };
  const [refreshing, setRefreshing] = useState(false);
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const [showTop, setShowTop] = useState(false);

  React.useEffect(() => {
    getBannerList(2)
      .then(banner => {
        setBannerList(banner);
      })
      .finally(() => setPageLoading(false));
  }, []);

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollY = event.nativeEvent.contentOffset.y;
    if (scrollY > screenHeight && !showTop) {
      setShowTop(true);
    }
    if (scrollY < screenHeight && showTop) {
      setShowTop(false);
    }
  };

  const handleToTop = () => {
    scrollRef.current?.scrollTo({animated: true, y: 0});
  };

  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };
  const [tabIndex, setTabIndex] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
  const refreshCount = React.useRef(0);
  const handleFocusEffect = React.useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      doNotice(token);
      globalStore.amountCheckOut.next({});
      if (token) {
        globalStore.refreshNotification.next();
      }
    });
    homeStore.refreshEnd.pipe(first()).subscribe(() => {
      setPageLoading(false);
    });
    const refreshedSub = homeStore.refreshEnd.subscribe(() => {
      if (++refreshCount.current === 2) {
        refreshCount.current = 0;
        setRefreshing(false);
      }
    });
    const changeTabSub = homeStore.changeTab.subscribe(index => {
      setTabIndex(index);
    });
    return () => {
      sub.unsubscribe();
      refreshedSub.unsubscribe();
      changeTabSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(handleFocusEffect);

  const homeRefresh = () => {
    doNotice(globalStore.token);
    homeStore.refresh.next();
  };

  const [showMap] = React.useState([true, false, false, false]);
  return (
    <Spin loading={pageLoading} style={[theme.flex.col, theme.fill.fill]}>
      <View
        style={[
          theme.fill.fill,
          theme.flex.col,
          theme.background.lightGrey,
          theme.position.rel,
        ]}>
        <HomeHeader setMenuOpen={setMenuOpen} version={version} />
        {globalStore.isWeb ? <Download /> : null}
        <ScrollView
          ref={scrollRef}
          style={[theme.flex.flex1]}
          stickyHeaderIndices={[2]}
          scrollEventThrottle={16}
          onScroll={handleScroll}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                homeRefresh();
                setVersion(v => v + 1);
              }}
            />
          }>
          <HomeBanner bannerList={bannerList} />
          {isA ? (
            <HomeSwiperGame />
          ) : (
            <HomeGoldArea
              noticeMap={noticeMap}
              onNotice={() => doNotice(globalStore.token)}
            />
          )}
          <HomeTab
            value={tabIndex}
            setValue={value => {
              showMap[value] = true;
              setTabIndex(value);
            }}
            isA={isA}
          />
          {(isA
            ? [HomeHot, HomeLive, HomeCasino, null, HomeScratch, HomeLottery]
            : [HomeLottery, HomeScratch, HomeLive, HomeCasino]
          ).map((V, i) => (
            <View
              key={i}
              style={[
                // eslint-disable-next-line react-native/no-inline-styles
                {height: i === tabIndex ? 'auto' : 0, overflow: 'hidden'},
              ]}>
              {V ? <V /> : null}
              {/*{showMap[i] ? <V /> : null}*/}
            </View>
          ))}
          <HomeBigWin />
          <NoMoreData />
        </ScrollView>
        <RightMenus hasTop={showTop} hasBonus onTop={handleToTop} />
      </View>
      <Drawer drawerWidth={280} ref={menuRef}>
        <HomeMenu setMenuOpen={setMenuOpen} unreadCount={unreadCount} />
      </Drawer>
    </Spin>
  );
};

export default Home;
