import React, {useEffect, useState, memo} from 'react';
import {View} from 'react-native';
import {useResponsiveDimensions} from '@/utils';
import DigitResult from './digit-result/result';
import OrderList from './digit-order/order-list';
import globalStore from '@services/global.state';
import InsideIm from '@/common-pages/im/insideIm';

const DigitTabView = ({
  refreshCode,
  pickThreeId,
  tabKey,
  type = 'normal',
}: {
  refreshCode: number;
  pickThreeId?: number;
  tabKey: string;
  type?: string;
}) => {
  const {height: screenHeight} = useResponsiveDimensions();
  const renderTabView = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [token, setToken] = useState(localStorage.getItem('token'));
    console.log('token', token);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const sub = globalStore.tokenSubject.subscribe(token => {
        setToken(token || '');
      });
      return () => {
        sub.unsubscribe();
      };
    }, []);
    switch (tabKey) {
      case 'result':
        return (
          <DigitResult
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={true}
          />
        );
      case 'order':
        return (
          <OrderList
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={true}
          />
        );
      case 'chat':
        return <InsideIm />;
    }
  };
  return (
    <View
      style={[
        {
          minHeight: screenHeight - 100,
        },
      ]}>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: '100%', overflow: 'hidden'},
        ]}>
        {renderTabView()}
      </View>
    </View>
  );
};

export default memo(DigitTabView);
