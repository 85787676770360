import React from 'react';
import {goTo} from '@utils';
// @ts-ignore
const lockIcon = require('@/assets/imgs/lock.png');
const imBg = require('@/assets/imgs/im-backdrop-filter.png');
export default function NotLoggedPage() {
  const handleLogin = () => {
    goTo('Login');
  };
  return (
    <div
      className={`text-white h-full font-bold text-[20px] bg-cover flex justify-center items-center flex-col text-center`}
      style={{backgroundImage: `url(${imBg})`}}>
      <div className="bg-black/50 rounded-full w-[72px] h-[72px] flex items-center justify-center">
        <img src={lockIcon} alt="" className="w-[61px] h-[59px]" />
      </div>
      <div className="mt-[20px]">You Are Not Logged In Yet</div>
      <div className="font-medium text-[14px] mt-[8px]">
        Log in to your account to view the content in the chat room
      </div>
      <div
        onClick={handleLogin}
        className="text-[16px] flex items-center justify-center bg-[#1D9D00] rounded-xl shadow-loggedCustom w-[126px] p-3 mt-[20px] active:brightness-75">
        LOGIN
      </div>
    </div>
  );
}
